import Home from "../pages/home/App";
import contato from "../pages/contato/index";
import empresa from "../pages/empresa/index";
import videos from "../pages/videos/index";
import produto from "../pages/produto/index";
import produtoEsp from "../pages/produtoEspecifico/index";
import SwiperPage from "../pages/swiper";

const routes = {
  public: [
    {
      title: "Home | Marvini",
      path: "/",
      hidden: true,
      component: Home,
    },
    {
      title: "Contato | Marvini",
      path: "/contato",
      hidden: true,
      component: contato,
    },
    {
      title: "História | Marvini",
      path: "/empresa",
      hidden: true,
      component: empresa,
    },
    {
      title: "Vídeos | Marvini",
      path: "/videos",
      hidden: true,
      component: videos,
    },
    {
      title: "Produtos | Marvini",
      path: "/produtos",
      hidden: true,
      component: produto,
    },
    {
      title: "Produto tal | Marvini",
      path: "/produto/:id_produto",
      hidden: true,
      component: produtoEsp,
    },
    {
      title: "SwiperPage | Marvini",
      path: "/Swiper",
      hidden: true,
      component: SwiperPage,
    },
  ],
};
export default routes;
