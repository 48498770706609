import { useHistory } from 'react-router-dom'

const MobileCarrouselComponent = ({ data, from }) => {
  const history = useHistory()

  return (
    <div
      style={{
        overflowX: 'auto',
        display: 'flex',
        flexDirection: from === 'products' ? 'column' : 'row',
        gap: '16px',
        marginTop: '46px',
      }}
    >
      {data?.map((item, index) => {
        return (
          <div
            key={item?.id_produto}
            onClick={() => history.push(`/produto/${item?.id_produto}`)}
            style={
              from === 'products'
                ? { display: 'flex', marginLeft: 0, marginRight: 0 }
                : index === 0
                ? { display: 'flex', marginLeft: '17px' }
                : index === data.length - 1
                ? { display: 'flex', marginRight: '31px' }
                : { display: 'flex' }
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                borderRadius: '3px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.100345)',
                minWidth: '270px',
                maxWidth: '270px',
                minHeight: '320px',
                border: '1px solid #e5e5e5',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  fontWeight: '700',
                  fontSize: '24px',
                  lineHeight: '32px',
                  textAlign: 'center',
                  color: '#333',
                }}
              >
                {item?.nome}
              </span>
              <img
                alt="produto"
                src={`https://api.marvini.com.br/${item?.image1}`}
                style={{
                  minWidth: '163px',
                  minHeight: '154px',
                  maxHeight: '154px',
                  maxWidth: '163px',
                }}
              />
              <span
                style={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#333',
                  opacity: '0.5',
                }}
              >
                {item?.subtitulo}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MobileCarrouselComponent
