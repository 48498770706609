import { useEffect, useState } from 'react'
import MobileHeader from '../../MobileHeader'
import MobileFooter from '../../MobileFooter'
import api from '../../../services/api'
import { useTranslation } from 'react-i18next'
import './videos.css'

export default function Videos() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [showSideBar, setShowSideBar] = useState(false)
  const [lingua, setLingua] = useState('portugues')
  const [video1, setVideo1] = useState(null)
  const [video2, setVideo2] = useState(null)
  const [video3, setVideo3] = useState(null)
  const [video4, setVideo4] = useState(null)
  const [video5, setVideo5] = useState(null)
  const [video6, setVideo6] = useState(null)

  useEffect(() => {
    handleGetVideo1()
  }, [])

  const handleGetVideo1 = async () => {
    try {
      const response = await api.get('videos/video1')

      if (response?.status === 200) {
        setVideo1(response?.data?.videos)
        handleGetVideo2()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetVideo2 = async () => {
    try {
      const response = await api.get('videos/video2')

      if (response?.status === 200) {
        setVideo2(response?.data?.videos)
        handleGetVideo3()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetVideo3 = async () => {
    try {
      const response = await api.get('videos/video3')

      if (response?.status === 200) {
        setVideo3(response?.data?.videos)
        handleGetVideo4()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetVideo4 = async () => {
    try {
      const response = await api.get('videos/video4')

      if (response?.status === 200) {
        setVideo4(response?.data?.videos)
        handleGetVideo5()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetVideo5 = async () => {
    try {
      const response = await api.get('videos/video5')

      if (response?.status === 200) {
        setVideo5(response?.data?.videos)
        handleGetVideo6()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleGetVideo6 = async () => {
    try {
      const response = await api.get('videos/video6')

      if (response?.status === 200) {
        setVideo6(response?.data?.videos)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="header-bg">
        <span>{t('videos')}</span>
        <span style={{ fontSize: '53px', marginTop: '-20px' }}>-</span>
      </div>
      <div className="videos-container">
        {video1 ? (
          <div className="video-card">
            <iframe
              style={{ marginTop: '28px' }}
              src={video1[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}

        {video2 ? (
          <div className="video-card">
            <iframe
              src={video2[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}

        {video3 ? (
          <div className="video-card">
            <iframe
              src={video3[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}

        {video4 ? (
          <div className="video-card">
            <iframe
              src={video4[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}

        {video5 ? (
          <div className="video-card">
            <iframe
              src={video5[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}

        {video6 ? (
          <div className="video-card">
            <iframe
              style={{ marginBottom: '65px' }}
              src={video6[0]?.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="spinner" />
        )}
      </div>
      <MobileFooter t={t} />
    </div>
  )
}
