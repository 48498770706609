import { useEffect, useState } from 'react'
import MobileHeader from '../../MobileHeader'
import MobileFooter from '../../MobileFooter'
import { useTranslation } from 'react-i18next'
import ImageGallery from 'react-image-gallery'
import './productShow.css'
import api from '../../../services/api'
import { useParams } from 'react-router-dom'

export default function ProductShow() {
  const { id_produto } = useParams()

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [showSideBar, setShowSideBar] = useState(false)
  const [lingua, setLingua] = useState('portugues')
  const [nome, setNome] = useState('')
  const [codigo, setCodigo] = useState('')
  const [codigoReferencia, setCodigoReferencia] = useState('')
  const [numero, setNumero] = useState('')
  const [subtitulo, setSubtitulo] = useState('')
  const [sensor, setSensor] = useState('')
  const [bico, setBico] = useState('')
  const [volumeMax, setVolumeMax] = useState('')
  const [volumeMin, setVolumeMin] = useState('')
  const [descricao, setDescricao] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [lista, setlista] = useState([])

  useEffect(() => {
    exibirProduto()
  }, [])

  const exibirProduto = async () => {
    const teste3 = await api.get(`/produtos/${id_produto}`)
    setNome(teste3.data.produto.nome)
    setCodigo(teste3.data.produto.codigo)
    setCodigoReferencia(teste3.data.produto.codigoReferencia)
    setNumero(teste3.data.produto.numero)
    setSubtitulo(teste3.data.produto.subtitulo)
    setSensor(teste3.data.produto.sensor)
    setBico(teste3.data.produto.bico)
    setVolumeMax(teste3.data.produto.volumeMax)
    setVolumeMin(teste3.data.produto.volumeMin)
    setDescricao(teste3.data.produto.descricao)
    setDataCriacao(teste3.data.produto.criacao)
    console.log(teste3.data.produto)
    let array_images = []
    if (teste3.data.produto.image1)
      array_images.push({
        original: `https://api.marvini.com.br/${teste3.data.produto.image1}`,
        thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image1}`,
      })
    if (teste3.data.produto.image2)
      array_images.push({
        original: `https://api.marvini.com.br/${teste3.data.produto.image2}`,
        thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image2}`,
      })
    if (teste3.data.produto.image3)
      array_images.push({
        original: `https://api.marvini.com.br/${teste3.data.produto.image3}`,
        thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image3}`,
      })
    if (teste3.data.produto.image4)
      array_images.push({
        original: `https://api.marvini.com.br/${teste3.data.produto.image4}`,
        thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image4}`,
      })
    if (teste3.data.produto.image5)
      array_images.push({
        original: `https://api.marvini.com.br/${teste3.data.produto.image5}`,
        thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image5}`,
      })
    setlista(array_images)
  }

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="productShow-bg">
        <span>{t('produtos')}</span>
        <span style={{ fontSize: '53px', marginTop: '-20px' }}>-</span>
      </div>
      <div className="product-container">
        <div className="box-image-product">
          <div
            style={{
              border: '1px solid #E5E5E5',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.100345)',
            }}
          >
            <ImageGallery
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              showPlayButton={false}
              showNav={true}
              slideOnThumbnailOver
              items={lista}
            />
          </div>
          <div className="box-product-info">
            <span style={{ marginInline: '16px' }}>{nome}</span>
            <span style={{ marginInline: '16px' }}>
              {t('CÓDIGO')}: <span style={{ fontWeight: '400' }}>{codigo}</span>
            </span>
            <span
              style={{
                marginInline: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {t('códigoDeReferência')}:{' '}
              {codigoReferencia ? (
                <span style={{ fontWeight: '400' }}>{codigoReferencia}</span>
              ) : (
                <span style={{ fontWeight: '400' }}>
                  {t('codigoRefNaoEspecificado')}
                </span>
              )}
            </span>
          </div>
          <div className="desc-container">
            <div className="desc-div">
              <span>{t('Descrição')}</span>
            </div>
            <div className="desc-box-infos">
              <span style={{ marginInline: '12px' }}>{descricao}</span>
              <span>
                <b>{t('Subtítulo')}:</b> {subtitulo}
              </span>
              {sensor === 'Não possui' ? (
                false
              ) : (
                <span>
                  <b>{t('Sensor')}:</b> {sensor}
                </span>
              )}
              {bico === 'Não possui' ? (
                false
              ) : (
                <span>
                  <b>{t('Bico')}:</b> {bico}
                </span>
              )}
              <span>
                <b>{t('VolumeMáximoDeÁgua')}:</b> {volumeMax} ml
              </span>
              <span>
                <b>{t('VolumeMínimoDeÁgua')}:</b> {volumeMin} ml
              </span>
            </div>
          </div>
        </div>
        <div className="div-container-contact">
          <span>{t('EntreEmContatoConosco')}!</span>
        </div>
      </div>

      <MobileFooter t={t} />
    </div>
  )
}
