/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import FlagsButton from "../../components/FlagsButton";
import Search from "../../components/searchButton/search";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Pagination from "@mui/material/Pagination";
import "./produto.css";
import {
  App,
  Container,
  Header,
  TitleHome,
  FraseTitle,
  Container5,
  ContainerFinalpage,
  Background2,
  Container5Text2,
  Container5Buttons,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ContainerAcesso,
  Container5Text3,
  ButtonInicio,
  ButtonEmpresa,
  ButtonProdutos,
  ButtonContato,
  ButtonVideos,
  Background3,
  Background4,
} from "./styles";
import api from "../../services/api";
import certificadoIso from "../../images/certificadoIso.png";
import garantia from "../../images/garantia.png";
import logoMarvini from "../../images/logoMarvini.png";
import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  Typography,
  Card,
  Box,
} from "@mui/material";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Products from "../../components/MobileMarvini/Products/products";

const produto: React.FC = () => {
  const { t } = useTranslation();

  const [produtos, setProdutos] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [abrirVeiculo, setAbrirVeiculo] = useState(false);
  const [abrirMontadoraCarro, setAbrirMontadoraCarro] = useState(false);
  const [abrirMontadoraCaminhao, setAbrirMontadoraCaminhao] = useState(false);
  const [abrirLinha, setAbrirLinha] = useState(false);
  const [abrirAno, setAbrirAno] = useState(false);
  const [data, setData] = useState<any>(null);
  const [search, setSearch] = useState("");

  const [veiculo, setVeiculo] = useState("");
  const [exclusive, setExclusive] = useState(false);
  const [totalProdutos, setTotalProdutos] = useState(0);
  const [montadoraVeiculo, setMontadoraVeiculo] = useState("");
  const [montadoraCaminhao, setMontadoraCaminhao] = useState("");
  const { width } = useWindowDimensions();

  // Fix #1: Quando eu voltar de produtos exclusivos para produtos, fazer a requisição de get all e reiniciar a paginação

  const optionsVeiculo = [
    "Audi",
    "BMW",
    "Citroen",
    "Daewoo",
    "Fiat",
    "Ford",
    "GM",
    "Hyundai",
    "Jeep",
    "Kia",
    "Land Rover",
    "Mercedes Benz",
    "Mitsubishi",
    "Nissan",
    "Peugeot",
    "Renault",
    "Toyota",
    "Troller",
    "Volkswagen",
    "Volvo",
    t("filtro-Trator"),
  ];

  const productLineOptions = [
    t("filtro-Reservatorios"),
    t("filtro-Tampas"),
    t("filtro-DutosDeAr"),
    t("filtro-Coletor"),
    t("filtro-Tubos"),
    t("filtro-Mangueiras"),
    t("filtro-Suportes"),
    t("filtro-LinhaPesada"),
    t("filtro-Acessorios"),
    t("filtro-DescansaBraco"),
    t("filtro-GuardaPo"),
    t("filtro-Calco"),
  ];

  const vehicleOptions = [
    t("filtro-Automovel"),
    t("filtro-Caminhao"),
    t("filtro-Trator"),
  ];

  function abrirRadioVeiculo() {
    if (abrirVeiculo === true) {
      setAbrirVeiculo(false);
    } else {
      setAbrirVeiculo(true);
    }
  }

  function abrirRadioMontadoraCarro() {
    if (abrirMontadoraCarro === true) {
      setAbrirMontadoraCarro(false);
    } else {
      setAbrirMontadoraCarro(true);
    }
  }

  function abrirRadioAno() {
    if (abrirAno === true) {
      setAbrirAno(false);
    } else {
      setAbrirAno(true);
    }
  }

  function abrirRadioLinha() {
    if (abrirLinha === true) {
      setAbrirLinha(false);
    } else {
      setAbrirLinha(true);
    }
  }

  async function handleChangeSelect(e: any) {
    const value = e.target.value;
    if (e.target.name === "veiculo") {
      setVeiculo(value);
    }
  }

  async function handleChangeTipo(e: any) {
    const value = e.target.value;
    if (veiculo === "Automóvel") {
      setMontadoraCaminhao("");
      setMontadoraVeiculo(value);
    } else if (veiculo === "Caminhão") {
      setMontadoraVeiculo("");
      setMontadoraCaminhao(value);
    }
  }

  interface produto {
    id_produto: any;
    codigo: any;
    numero: any;
    nome: any;
    subtitulo: any;
    criacao: any;
    descricao: any;
    image1: any;
    veiculo: any;
    data: {
      id_produto: string;
      codigo: string;
      numero: string;
      nome: string;
      subtitulo: string;
      criacao: string;
      descricao: string;
      image1: string;
      veiculo: any;
    };
  }

  useEffect(() => {
    if (search === "" && data === null) {
      loadPage();
      handleGetAllProdutos();
    }
  }, [pagina]);

  useEffect(() => {
    if (search !== "" || data !== null) handleFilterSearch();
  }, [
    pagina,
    data?.veiculo,
    data?.montadoraVeiculo,
    data?.montadoraCaminhao,
    data?.linhaProduto,
    data?.ano,
  ]);

  async function loadPage() {
    try {
      setLoading(true);

      const response = await api.get<produto[], any>(
        `/produtos/items/?page=${pagina}`
      );

      if (response.status === 200) {
        setProdutos(response.data.produtos);
        setExclusive(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const handleGetAllProdutos = async () => {
    try {
      const response = await api.get<produto[], any>(`/produtos`);

      if (response?.status === 200) {
        setTotalProdutos(response?.data?.produtos?.length);
        setExclusive(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function loadLancamentos() {
    try {
      setLoading(true);

      const response = await api.get<produto[], any>(`/produtos/carrossel2`);

      if (response?.status === 200) {
        setProdutos(response?.data?.produtos);
        setExclusive(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setPagina(1);

    if (e.target.name) {
      setData({ ...data, [e.target.name]: e.target.value });
    } else if (veiculo === "Automóvel") {
      setData({
        ...data,
        montadoraCaminhao: "",
        montadoraVeiculo: e.target.value,
      });
    } else {
      setData({
        ...data,
        montadoraVeiculo: "",
        montadoraCaminhao: e.target.value,
      });
    }
  }

  const getProductLineOptionValues = (value) => {
    let option = value;

    if (
      option === "Reservatórios" ||
      option === "Reservoirs" ||
      option === "Embalses"
    )
      option = "Reservatório";
    if (option === "Lids" || option === "Tapas") option = "Tampas";
    if (option === "Air ducts" || option === "Conductos de aire")
      option = "Dutos de ar";
    if (option === "Intake manifold" || option === "Colector de admisión")
      option = "Coletor de admissão";
    if (option === "Tubes" || option === "Tubos") option = "Tubos";
    if (option === "Hoses" || option === "Mangueras") option = "Mangueiras";
    if (option === "Supports" || option === "Apoya") option = "Suportes";
    if (option === "Heavy line" || option === "Línea gruesa")
      option = "Linha pesada";
    if (option === "Accessories" || option === "Accesorios")
      option = "Acessórios";
    if (option === "Arm Rest" || option === "Reposabrazos")
      option = "Descansa Braço";
    if (option === "Guard Powder" || option === "Polvo protector")
      option = "Guarda pó";
    if (option === "Shim" || option === "Calce") option = "Calço";

    return option;
  };

  const getVehicleOptionValues = (value) => {
    let option = value;

    if (option === "Coche" || option === "Car") option = "Automóvel";
    if (option === "Camión" || option === "Truck") option = "Caminhão";
    if (option === "Tractor" || option === "Tractor") option = "Trator";

    return option;
  };

  async function handleFilterSearch() {
    try {
      setLoading(true);

      let response;

      if (data === null) {
        response = await api.get(`/procura?page=${pagina}&search=${search}`);
      } else {
        response = await api.get<any>(
          `/procura?page=${pagina}&search=${search ? search : ""}&veiculo=${
            data?.veiculo
          }&montadoraVeiculo=${data?.montadoraVeiculo}&montadoraCaminhao=${
            data?.montadoraCaminhao
          }&linhaProduto=${data?.linhaProduto}&ano=${data?.ano}`
        );
      }

      if (
        response?.data?.products_page_count === 0 &&
        response?.data?.produtos.length === 0
      )
        setPagina(1);

      if (response?.status === 200) {
        setProdutos(response?.data?.produtos);
        setTotalProdutos(response?.data?.total);
        setExclusive(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function teste5(e: any) {
    handleChangeSelect(e);
    handleChangeTipo(e);
    handleSearch(e);
  }

  const handleShowYearFilterOptions = () => {
    const years = [];

    for (let i = 1960; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }

    return years.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={item.toString()}
          label={item.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const showProductLineFilterOptions = () => {
    return productLineOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={getProductLineOptionValues(item)}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const showVehiclesFilterOptions = () => {
    return vehicleOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={getVehicleOptionValues(item)}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  return width < 600 ? (
    <Products />
  ) : (
    <App>
      <Header>
        <Container>
          <div className="botoesInicio">
            <img
              className="btn"
              onClick={() => history.push("/")}
              style={{
                width: "180px",
                height: "auto",
                position: "absolute",
                left: "70px",
                top: "-30%",
                backgroundColor: "white",
                borderRadius: "100px",
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push("/empresa")}>
              {t("btnEmpresa")}
            </button>
            <button className="btn" onClick={() => history.push("/produtos")}>
              {t("btnProduto")}
            </button>
            <button className="btn" onClick={() => history.push("/contato")}>
              {t("btnContato")}
            </button>
            <button className="btn" onClick={() => history.push("/videos")}>
              {t("btnVideos")}
            </button>
          </div>

          <div
            style={{
              fontSize: "20px",
              position: "absolute",
              right: "30px",
              top: "110px",
            }}
          >
            {t("marvini")} <b>{t("valoriza")}</b> {t("seuCarro")}
          </div>
          <TitleHome>
            {exclusive ? t("produtosExclusivoTitulo") : t("produtosTitulo")}
            <br />―
          </TitleHome>
          <FlagsButton setLingua={() => {}} />
        </Container>
      </Header>
      <div className="lancamentos" style={{ marginTop: "0" }}>
        <button
          className="lancamentosBtn"
          style={{
            padding: ".5rem",
            margin: "2% 0 2% 80%",
            background: "linear-gradient(to top, #0a0a60, #0e77ee)",
            borderRight: "3px solid #0a0a60",
            cursor: "pointer",
          }}
          onClick={() => {
            if (exclusive) {
              loadPage();
              setPagina(1);
              setExclusive(false);
            } else loadLancamentos();
          }}
        >
          {exclusive ? t("btnProduto") : t("LANCAMENTOS")}
        </button>
      </div>
      <div style={{ position: "relative", top: "4em", marginLeft: "2.3%" }}>
        <button
          onClick={() => document.location.reload(true)}
          className="btn-reset"
          style={{ cursor: "pointer" }}
        >
          {t("btn-reset-filtros")}
        </button>
      </div>
      <div className="wrap">
        <div className="search">
          <input
            name="search"
            type="text"
            className="searchTerm"
            placeholder={t("PesquisePelosCódigos,NomeOuNúmero")}
            onChange={(e) => {
              if (e.target.value === "") {
                setPagina(1);
                loadPage();
                handleGetAllProdutos();
              }
              setSearch(e.target.value);
            }}
          />
          <button
            disabled={search === ""}
            type="submit"
            className="searchButton"
            onClick={() => {
              setPagina(1);
              handleFilterSearch();
            }}
          >
            <Search />
          </button>
        </div>
      </div>
      {/* <FraseTitle3>{t("Filtros")}</FraseTitle3> */}
      <div className="quadrado">
        <div className="wrap-collabsible">
          <input
            onClick={abrirRadioLinha}
            id="collapsible4"
            className="toggle"
            type="checkbox"
            checked={abrirLinha}
          />
          <label htmlFor="collapsible4" className="lbl-toggle">
            {t("LinhadeProdutos")}
          </label>
          <div className="collapsible-content">
            <div className="content-inner">
              <RadioGroup
                aria-label="linhaProduto"
                name="linhaProduto"
                onChange={handleSearch}
              >
                {showProductLineFilterOptions()}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="wrap-collabsible">
          <input
            onClick={abrirRadioVeiculo}
            id="collapsible"
            className="toggle"
            type="checkbox"
            checked={abrirVeiculo}
          />
          <label htmlFor="collapsible" className="lbl-toggle">
            {t("Veículo")}
          </label>
          <div className="collapsible-content">
            <div className="content-inner">
              <RadioGroup aria-label="veiculo" name="veiculo" onChange={teste5}>
                {showVehiclesFilterOptions()}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="wrap-collabsible">
          <input
            onClick={abrirRadioMontadoraCarro}
            id="collapsible2"
            className="toggle"
            type="checkbox"
            checked={abrirMontadoraCarro}
          />
          <label htmlFor="collapsible2" className="lbl-toggle">
            {t("Montadoras")}
          </label>
          <div className="collapsible-content">
            <div className="content-inner" id="esconder">
              <RadioGroup
                aria-label="montadoraVeiculo"
                name=""
                onChange={teste5}
              >
                {optionsVeiculo?.map((item: any, index: any) => (
                  <FormControlLabel
                    value={item === "Tractor" ? "Trator" : item}
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": {
                            color: "#3978b3",
                          },
                        }}
                      />
                    }
                    label={item}
                    sx={{ color: "black" }}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="wrap-collabsible">
          <input
            onClick={abrirRadioAno}
            id="collapsible3"
            className="toggle"
            type="checkbox"
            checked={abrirAno}
          />
          <label htmlFor="collapsible3" className="lbl-toggle">
            {t("Ano")}
          </label>
          <div className="collapsible-content">
            <div className="content-inner">
              <RadioGroup aria-label="ano" name="ano" onChange={handleSearch}>
                {handleShowYearFilterOptions()}
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-line"></div>
      {exclusive ? (
        <>
          <div
            style={{
              minHeight: "20vh",
              minWidth: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FraseTitle style={{ marginBottom: "1rem" }}>
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #0a0a60, #0e77ee)",
                  height: 8,
                  width: 270,
                  marginRight: 14,
                }}
              />
              {t("produtosExclusivoTitulo")}
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #0e77ee, #0a0a60)",
                  height: 8,
                  width: 270,
                  marginLeft: 14,
                }}
              />
            </FraseTitle>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              minHeight: "20vh",
              minWidth: "50%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FraseTitle style={{ marginBottom: "1rem" }}>
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #0a0a60, #0e77ee)",
                  height: 8,
                  width: 360,
                  marginRight: 14,
                }}
              />
              {t("produtosTitulo")}
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #0e77ee, #0a0a60)",
                  height: 8,
                  width: 360,
                  marginLeft: 14,
                }}
              />
            </FraseTitle>
          </div>
        </>
      )}

      <div style={{ minHeight: "100vh" }}>
        {loading ? (
          <div className="middle">
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
            <div className="bar bar4"></div>
            <div className="bar bar5"></div>
            <div className="bar bar6"></div>
            <div className="bar bar7"></div>
            <div className="bar bar8"></div>
          </div>
        ) : (
          <Grid
            sx={{
              marginLeft: "35%",
              backgroundColor: "white",
              width: "60%",
              height: "40%",
              textAlign: "center",
              marginBottom: "2%",
            }}
            container
          >
            {produtos?.map((row: produto, index) => (
              <Grid
                item
                xs={12}
                lg={6}
                xl={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Card
                  onClick={() => history.push(`/produto/${row.id_produto}`)}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "350px",
                    height: "350px",
                    minHeight: "350px",
                    minWidth: "350px",
                    padding: "1.8rem",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    borderRight: "2px solid #d1d7d5",
                    borderBottom: "2px solid #d1d7d5",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      maxHeight: "210px",
                      height: "210px",

                      [theme.breakpoints.down("xl")]: {
                        maxHeight: "210px",
                        height: "210px",
                      },
                      [theme.breakpoints.down("lg")]: {
                        maxHeight: "210px",
                        height: "210px",
                      },
                      objectFit: "contain",
                    })}
                    component="img"
                    className="prod1"
                    src={`https://api.marvini.com.br/${row?.image1}`}
                    alt=""
                  />
                  <Typography
                    variant="body1"
                    title={row.nome}
                    sx={{
                      paddingTop: 4,
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      fontFamily: "Nunito, sans-serif",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 300,
                    }}
                    onClick={() => history.push(`/produto/${row.id_produto}`)}
                  >
                    {row.nome}
                  </Typography>
                  <Typography
                    title={row.descricao}
                    sx={{
                      color: "text.secondary",
                      fontFamily: "Nunito, sans-serif",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 350,
                    }}
                    variant="caption"
                  >
                    {row.descricao}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      {exclusive ? (
        false
      ) : (
        <Pagination
          page={pagina}
          onChange={(o, p) => setPagina(p)}
          count={Math.ceil(totalProdutos / 6)}
          sx={{ marginBottom: "3%", marginLeft: "45%" }}
          color="primary"
        />
      )}
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          <Container5Text2>{t("nosSiga")}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t("acessoRapido")}</Container5Text3>
          <ButtonInicio onClick={() => history.push("/")}>
            {t("inicio")}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push("/empresa")}>
            {t("empresa")}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push("/produtos")}>
            {t("produtos")}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push("/contato")}>
            {t("contato")}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push("/videos")}>
            {t("videos")}
          </ButtonVideos>
        </ContainerAcesso>
        <Background3 src={garantia}></Background3>
        <Background4 src={certificadoIso}></Background4>
      </Container5>
    </App>
  );
};

export default produto;
