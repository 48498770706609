import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pt from "./resources/pt.json";
import en from "./resources/en.json";
import es from "./resources/es.json";

const resources = {
  en,
  pt,
  es,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pt",
  fallbackLng: "en" && "es",
});

export default i18n;
