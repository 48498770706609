import { useState, useEffect } from "react";
import MobileHeader from "../../MobileHeader";
import MobileFooter from "../../MobileFooter";
import { useTranslation } from "react-i18next";
import Search from "../../searchButton/search";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Pagination,
  LinearProgress,
  Box,
} from "@mui/material";
import api from "../../../services/api";
import MobileCarrouselComponent from "../../MobileCarrouselComponent";
import "./products.css";

export default function Products() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [showSideBar, setShowSideBar] = useState(false);
  const [lingua, setLingua] = useState("portugues");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isExclusive, setIsExclusive] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);
  const [vehicle, setVehicle] = useState("");
  const [radioFilterOptions, setRadioFilterOptions] = useState(null);

  useEffect(() => {
    if (search === "" && radioFilterOptions === null) {
      handleGetAllProducts();
      handleGetPaginationCounter();
    }
  }, [page]);

  useEffect(() => {
    if (search !== "" || radioFilterOptions !== null) handleFilterSearch();
  }, [
    page,
    radioFilterOptions?.veiculo,
    radioFilterOptions?.montadoraVeiculo,
    radioFilterOptions?.montadoraCaminhao,
    radioFilterOptions?.linhaProduto,
    radioFilterOptions?.ano,
  ]);

  const productLineOptions = [
    t("filtro-Reservatorios"),
    t("filtro-Tampas"),
    t("filtro-DutosDeAr"),
    t("filtro-Coletor"),
    t("filtro-Tubos"),
    t("filtro-Mangueiras"),
    t("filtro-Suportes"),
    t("filtro-LinhaPesada"),
    t("filtro-Acessorios"),
    t("filtro-DescansaBraco"),
    t("filtro-GuardaPo"),
    t("filtro-Calco"),
  ];

  const vehicleOptions = [
    t("filtro-Automovel"),
    t("filtro-Caminhao"),
    t("filtro-Trator"),
  ];

  const automakersOptions = [
    "Audi",
    "BMW",
    "Citroen",
    "Daewoo",
    "Fiat",
    "Ford",
    "GM",
    "Hyundai",
    "Jeep",
    "Kia",
    "Land Rover",
    "Mercedes Benz",
    "Mitsubishi",
    "Nissan",
    "Peugeot",
    "Renault",
    "Toyota",
    "Troller",
    "Volkswagen",
    "Volvo",
    t("filtro-Trator"),
  ];

  let yearsOptions = [];

  for (let i = 1960; i <= new Date().getFullYear(); i++) {
    yearsOptions.push(i);
  }

  const getProductLineOptionValues = (value) => {
    let option = value;

    if (
      option === "Reservatórios" ||
      option === "Reservoirs" ||
      option === "Embalses"
    )
      option = "Reservatório";
    if (option === "Lids" || option === "Tapas") option = "Tampas";
    if (option === "Air ducts" || option === "Conductos de aire")
      option = "Dutos de ar";
    if (option === "Intake manifold" || option === "Colector de admisión")
      option = "Coletor de admissão";
    if (option === "Tubes" || option === "Tubos") option = "Tubos";
    if (option === "Hoses" || option === "Mangueras") option = "Mangueiras";
    if (option === "Supports" || option === "Apoya") option = "Suportes";
    if (option === "Heavy line" || option === "Línea gruesa")
      option = "Linha pesada";
    if (option === "Accessories" || option === "Accesorios")
      option = "Acessórios";
    if (option === "Arm Rest" || option === "Reposabrazos")
      option = "Descansa Braço";
    if (option === "Guard Powder" || option === "Polvo protector")
      option = "Guarda pó";
    if (option === "Shim" || option === "Calce") option = "Calço";

    return option;
  };

  const getVehicleOptionValues = (value) => {
    let option = value;

    if (option === "Coche" || option === "Car") option = "Automóvel";
    if (option === "Camión" || option === "Truck") option = "Caminhão";
    if (option === "Tractor" || option === "Tractor") option = "Trator";

    return option;
  };

  const showProductLineFilterOptions = () => {
    return productLineOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={getProductLineOptionValues(item)}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const showVehiclesFilterOptions = () => {
    return vehicleOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={getVehicleOptionValues(item)}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const showAutomakersFilterOptions = () => {
    return automakersOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={item === "Tractor" ? "Trator" : item?.toString()}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const showYearsOptions = () => {
    return yearsOptions?.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={item?.toString()}
          label={item?.toString()}
          control={
            <Radio
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3978b3",
                },
              }}
            />
          }
        />
      );
    });
  };

  const handleGetAllProducts = async () => {
    try {
      setIsLoading(true);

      const response = await api.get(`/produtos/items/?page=${page}`);

      if (response?.status === 200) {
        setData(response?.data?.produtos);
        setIsExclusive(false);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleGetPaginationCounter = async () => {
    try {
      const response = await api.get(`/produtos`);

      if (response?.status === 200) {
        setTotalProducts(response?.data?.produtos?.length);
        setIsExclusive(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetAllExclusiveProducts = async () => {
    try {
      setIsLoading(true);

      const response = await api.get("/produtos/carrossel2");

      if (response?.status === 200) {
        setData(response?.data?.produtos);
        setIsExclusive(true);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleSearch = (e, type) => {
    setPage(1);

    if (type === "veiculo") setVehicle(e.target.value);

    let montadoraVeiculo =
      radioFilterOptions === null
        ? "undefined"
        : radioFilterOptions?.montadoraVeiculo;
    let montadoraCaminhao =
      radioFilterOptions === null
        ? "undefined"
        : radioFilterOptions?.montadoraCaminhao;

    setRadioFilterOptions({
      ...radioFilterOptions,
      montadoraVeiculo:
        type === "montadora" ? e.target.value : montadoraVeiculo,
      montadoraCaminhao:
        type === "montadora" ? e.target.value : montadoraCaminhao,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterSearch = async () => {
    try {
      setIsLoading(true);

      let response;

      if (radioFilterOptions === null) {
        response = await api.get(`/procura?page=${page}&search=${search}`);
      } else {
        response = await api.get(
          `/procura?page=${page}&search=${search ? search : ""}&veiculo=${
            radioFilterOptions?.veiculo
          }&montadoraVeiculo=${
            radioFilterOptions.veiculo === undefined
              ? radioFilterOptions.montadoraVeiculo
              : vehicle === "Automóvel" && radioFilterOptions?.montadoraVeiculo
              ? radioFilterOptions?.montadoraVeiculo
              : "undefined"
          }&montadoraCaminhao=${
            radioFilterOptions.veiculo === undefined
              ? radioFilterOptions.montadoraCaminhao
              : (vehicle === "Caminhão" || vehicle === "Trator") &&
                radioFilterOptions?.montadoraCaminhao
              ? radioFilterOptions?.montadoraVeiculo
              : "undefined"
          }&linhaProduto=${radioFilterOptions?.linhaProduto}&ano=${
            radioFilterOptions?.ano
          }`
        );
      }

      if (
        response?.data?.products_page_count === 0 &&
        response?.data?.produtos.length === 0
      )
        setPage(1);

      if (response?.status === 200) {
        setData(response?.data?.produtos);
        setTotalProducts(response?.data?.total);
        setIsExclusive(false);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="bg-products">
        <span>{t("produtos")}</span>
        <span style={{ fontSize: "53px", marginTop: "-20px" }}>-</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="search-bar">
          <input
            onChange={(e) => {
              if (e.target.value === "") {
                setPage(1);
                handleGetAllProducts();
                handleGetPaginationCounter();
              }
              setSearch(e.target.value);
            }}
            type="search"
            className="search-input"
            placeholder={t("PesquisePelosCódigos,NomeOuNúmero")}
          />

          <button
            disabled={search === ""}
            onClick={() => {
              setPage(1);
              handleFilterSearch();
            }}
            className="search-btn"
          >
            <Search from="products" />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => document.location.reload(true)}
            className="btn-reset"
          >
            {t("btn-reset-filtros")}
          </button>
        </div>
      </div>
      <div className="search-container">
        <details>
          <summary>{t("LinhadeProdutos")}</summary>
          <RadioGroup
            aria-label="linhaProduto"
            name="linhaProduto"
            onChange={(e) => handleSearch(e, "linhaProduto")}
          >
            {showProductLineFilterOptions()}
          </RadioGroup>
        </details>
        <details>
          <summary>{t("Veículo")}</summary>
          <RadioGroup
            aria-label="veiculo"
            name="veiculo"
            onChange={(e) => handleSearch(e, "veiculo")}
          >
            {showVehiclesFilterOptions()}
          </RadioGroup>
        </details>
        <details>
          <summary>{t("Montadoras")}</summary>
          <RadioGroup
            aria-label="montadoraVeiculo"
            name={
              vehicle === "Automóvel" ? "montadoraVeiculo" : "montadoraCaminhao"
            }
            onChange={(e) => handleSearch(e, "montadora")}
          >
            {showAutomakersFilterOptions()}
          </RadioGroup>
        </details>
        <details>
          <summary>{t("Ano")}</summary>
          <RadioGroup
            aria-label="ano"
            name="ano"
            onChange={(e) => handleSearch(e, "ano")}
          >
            {showYearsOptions()}
          </RadioGroup>
        </details>
      </div>
      <button
        className="btn-switch-exclusive"
        onClick={() => {
          if (isExclusive) {
            handleGetAllProducts();
            setPage(1);
            setIsExclusive(false);
          } else handleGetAllExclusiveProducts();
        }}
      >
        {isLoading
          ? t("carregando")
          : isExclusive
          ? t("produtosTitulo")
          : t("produtosExclusivoTitulo")}
      </button>
      <div className="search-result">
        {isLoading ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <span
              style={{
                fontSize: "16px",
                color: "#0c14cc",
                marginTop: 14,
              }}
            >
              {t("carregando")}
            </span>
            <LinearProgress />
          </Box>
        ) : (
          <MobileCarrouselComponent data={data} from="products" />
        )}
        {isLoading || isExclusive ? (
          false
        ) : (
          <Pagination
            page={page}
            onChange={(o, p) => setPage(p)}
            count={Math.ceil(totalProducts / 6)}
            color="primary"
          />
        )}
      </div>
      <MobileFooter t={t} />
    </div>
  );
}
