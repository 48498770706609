/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import logoMarvini from "../../images/logoMarvini.png";
import FlagsButton from "../../components/FlagsButton";
import certificadoIso from "../../images/certificadoIso.png";
import garantia from "../../images/garantia.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./App.css";
import api from "../../services/api";
import {
  App,
  Container,
  Container2,
  Header,
  TitleHome,
  Container5,
  Container5Text,
  Container5Text2,
  Container5Text3,
  Background2,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ButtonContato,
  ButtonEmpresa,
  ButtonInicio,
  ButtonProdutos,
  ButtonVideos,
  Background3,
  Background4,
  Video1,
  TitleVideo,
  FraseVideo,
  Video2,
  ContainerFinalpage,
  Container5Buttons,
  ContainerAcesso,
} from "./styles";
import Videos from "../../components/MobileMarvini/Videos/Videos";

interface video {
  id_video: any;
  link: any;
  numero: any;
  data: {
    id_video: string;
    link: string;
    numero: string;
  };
}

const contato: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [lista1, setlista1] = useState<any>(Array());
  const [lista2, setlista2] = useState<any>(Array());
  const [lista3, setlista3] = useState<any>(Array());
  const [lista4, setlista4] = useState<any>(Array());
  const [lista5, setlista5] = useState<any>(Array());
  const [lista6, setlista6] = useState<any>(Array());
  const { width } = useWindowDimensions();

  useEffect(() => {
    async function exibirLink1() {
      const response = await api.get<video[], any>("videos/video4");
      setlista1(response.data.videos);
      // console.log(response.data.videos);
    }
    exibirLink1();

    async function exibirLink2() {
      const testeLink2 = await api.get<video[], any>("videos/video2");
      setlista2(testeLink2.data.videos);
    }
    exibirLink2();

    async function exibirLink3() {
      const testeLink3 = await api.get<video[], any>("videos/video3");
      setlista3(testeLink3.data.videos);
    }
    exibirLink3();

    async function exibirLink4() {
      const testeLink4 = await api.get<video[], any>("videos/video1");
      setlista4(testeLink4.data.videos);
    }
    exibirLink4();

    async function exibirLink5() {
      const testeLink5 = await api.get<video[], any>("videos/video5");
      setlista5(testeLink5.data.videos);
    }
    exibirLink5();

    async function exibirLink6() {
      const testeLink6 = await api.get<video[], any>("videos/video6");
      setlista6(testeLink6.data.videos);
    }
    exibirLink6();
  }, []);

  return width < 600 ? (
    <Videos />
  ) : (
    <App>
      <Header>
        <Container>
          <div className="botoesInicio">
            <img
              className="btn"
              onClick={() => history.push("/")}
              style={{
                width: "180px",
                height: "auto",
                position: "absolute",
                left: "70px",
                top: "-30%",
                backgroundColor: "white",
                borderRadius: "100px",
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push("/empresa")}>
              {t("btnEmpresa")}
            </button>
            <button className="btn" onClick={() => history.push("/produtos")}>
              {t("btnProduto")}
            </button>
            <button className="btn" onClick={() => history.push("/contato")}>
              {t("btnContato")}
            </button>
            <button className="btn" onClick={() => history.push("/videos")}>
              {t("btnVideos")}
            </button>
          </div>

          <div
            style={{
              fontSize: "20px",
              position: "absolute",
              right: "30px",
              top: "110px",
            }}
          >
            {t("marvini")} <b>{t("valoriza")}</b> {t("seuCarro")}
          </div>
          <TitleHome>
            {t("VÍDEOS")}
            <br />―
          </TitleHome>
          <FlagsButton setLingua={() => {}} />
        </Container>
      </Header>
      <Container2>
        <Video1>
          {lista1.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video1>
        <Video1>
          {lista2.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video1>
        <Video1>
          {lista3.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video1>
        <Video2>
          {lista4.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video2>
        <Video2>
          {lista5.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video2>
        <Video2>
          {lista6.map((item: any) => (
            <iframe
              width="100%"
              height="50%"
              src={item.link}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
          <TitleVideo>{t("Videos")}</TitleVideo>
          <FraseVideo>{t("VideosFrase")}</FraseVideo>
        </Video2>
      </Container2>
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          {/* <Container5Text>{t("descricaoFim")}</Container5Text> */}
          <Container5Text2>{t("nosSiga")}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t("acessoRapido")}</Container5Text3>
          <ButtonInicio onClick={() => history.push("/")}>
            {t("inicio")}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push("/empresa")}>
            {t("empresa")}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push("/produtos")}>
            {t("produtos")}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push("/contato")}>
            {t("contato")}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push("/videos")}>
            {t("videos")}
          </ButtonVideos>
        </ContainerAcesso>
        <Background3 src={garantia}></Background3>
        <Background4 src={certificadoIso}></Background4>
      </Container5>
    </App>
  );
};

export default contato;
