import { CgMenuLeftAlt } from "react-icons/cg/index";
import { IoCloseOutline } from "react-icons/io5/index";
import { useHistory } from "react-router-dom";
import "./styles.css";

function MobileHeader({ showSideBar, setShowSideBar, setLingua, i18n, t }) {
  const history = useHistory();

  const href = window.location.href.split("/")[3];

  return (
    <div className="app-header-mobile">
      <div>
        <CgMenuLeftAlt
          color={"#999999"}
          className="btn-sidebar"
          onClick={() => setShowSideBar(true)}
        />
        <div className="sideBar-body" style={showSideBar ? { left: 0 } : {}}>
          <div className="sideBar-close">
            <IoCloseOutline
              color={"#999999"}
              style={{
                width: "26px",
                height: "26px",
                padding: 0,
                marginTop: "14px",
                marginRight: "18px",
                cursor: "pointer",
              }}
              onClick={() => setShowSideBar(false)}
            />
          </div>
          <div className="sidebar-span-ctn">
            <button
              onClick={() => history.push("/empresa")}
              style={{
                backgroundColor: href === "empresa" ? "#e5e5e5" : "#fff",
              }}
            >
              <span style={{ color: href === "empresa" ? "#ea180b" : "#999" }}>
                {t("btnEmpresa")}
              </span>
            </button>
            <button
              onClick={() => history.push("/produtos")}
              style={{
                backgroundColor: href === "produtos" ? "#e5e5e5" : "#fff",
              }}
            >
              <span style={{ color: href === "produtos" ? "#ea180b" : "#999" }}>
                {t("btnProduto")}
              </span>
            </button>
            <button
              onClick={() => history.push("/contato")}
              style={{
                backgroundColor: href === "contato" ? "#e5e5e5" : "#fff",
              }}
            >
              <span style={{ color: href === "contato" ? "#ea180b" : "#999" }}>
                {t("btnContato")}
              </span>
            </button>
            <button
              onClick={() => history.push("/videos")}
              style={{
                backgroundColor: href === "videos" ? "#e5e5e5" : "#fff",
              }}
            >
              <span style={{ color: href === "videos" ? "#ea180b" : "#999" }}>
                {t("btnVideos")}
              </span>
            </button>
          </div>
        </div>
      </div>
      <img
        onClick={() => history.push("/")}
        src="/LogoMarvini.png"
        alt="Logo da Marvini"
        className="mobile-img-header"
        style={{ cursor: "pointer" }}
      />
      <div className="header-bandeiras">
        <img
          onClick={() => {
            i18n.changeLanguage("pt");
            setLingua("portugues");
          }}
          src="https://cdn-icons-png.flaticon.com/512/330/330430.png"
          alt="Ícone da bandeira do Brasil"
        />
        <img
          onClick={() => {
            i18n.changeLanguage("en");
            setLingua("ingles");
          }}
          src="https://cdn-icons-png.flaticon.com/512/330/330451.png"
          alt="Ícone da bandeira dos Austrália"
        />
        <img
          onClick={() => {
            i18n.changeLanguage("es");
            setLingua("espanhol");
          }}
          src="https://cdn-icons-png.flaticon.com/512/330/330557.png"
          alt="Ícone da bandeira da Espanha"
        />
      </div>
    </div>
  );
}

export default MobileHeader;
