import styled from "styled-components";
import mapaMundo from "../../images/mapa1.png";
import facebook from "../../images/facebook.png";
import youtube from "../../images/youtube.png";
import linkedin from "../../images/linkedin.png";
import instagram from "../../images/instagram.png";

export const App = styled.div`
  text-align: center;
  width: 100vw;
`;

export const Header = styled.div`
  background-color: #3a3736;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url(../../images/contato.png);
  background-size: cover;
  background-position: center;
`;

export const Container = styled.div`
  position: relative;
  height: 100vmin;
  width: 100%;
  display: inline-block;
  // background-color: rgba(0, 0, 0, 0.3);
  text-align: left;
`;

export const Background7 = styled.img`
  width: 205vmin;
  height: 100vmin;
  opacity: 0.5;
  position: relative;
`;

export const TitleHome = styled.div`
  display: flex;
  margin-top: 10%;
  margin-left: 43%;
  font-size: 60px;
  color: white;
  text-shadow: 0px 0px 5px black;
  font-weight: bold;
  text-align: center;
`;

export const Container2 = styled.div`
  display: grid;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ececec;
  background-position: right;
  background-image: url("${mapaMundo}");
  background-repeat: no-repeat;
  background-size: 45%;
`;

export const Gps = styled.div`
  position: relative;
`;

export const BoxAtendimento = styled.div`
  position: absolute;
  background-color: #2d32a8;
  min-height: 17%;
  min-width: 10%;
  margin-top: 15%;
  margin-left: 5%;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
`;

export const Image = styled.img`
  width: 40%;
  height: 70px;
  position: relative;
  margin-top: 20%;
  justify-content: center;
  align-items: center;
`;

export const ImageBox = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;

export const ImageBox1 = styled.img`
  position: absolute;
  width: 80px;
  height: 50px;
  margin-left: 32%;
  margin-top: -20%;
  z-index: 1;
`;

export const TitleContato = styled.div`
  position: absolute;
  min-width: 250%;
  text-align: left;
  margin-top: 25%;
  font-size: 35px;
  color: black;
  text-shadow: 0px 0px 5px white;
  font-weight: bold;
`;

export const TextContato = styled.div`
  position: absolute;
  min-width: 250%;
  margin-top: 60%;
  left: 0%;
  font-size: 20px;
  color: black;
  text-align: justify;
  text-justify: inter-word;
  text-shadow: 0px 0px 5px white;
`;

export const Container3Pontos = styled.div`
  position: absolute;
  margin-top: 20%;
  margin-left: 45%;
  display: no-repeat;
  font-size: 10px;
  color: gray;
  font-weight: bold;
`;

export const BoxPredio = styled.div`
  position: absolute;
  background-color: #1976d2;
  height: 100px;
  width: 100px;
  top: 3%;
  right: 85%;
  text-align: center;
  border-radius: 50px;
`;

export const BoxPredioText = styled.div`
  position: absolute;
  background-color: #c9e9ec;
  height: 13%;
  width: 25%;
  margin-top: 9%;
  right: 8%;
  text-align: center;
  border-radius: 10px;
`;

export const BoxPredioTextMargin = styled.div`
  position: absolute;
  height: 70%;
  width: 80%;
  margin-left: 15%;
  border-radius: 10px;
  margin-top: 6%;
  font-size: 15px;
`;

export const BoxTelefone = styled.div`
  position: absolute;
  background-color: #1976d2;
  min-height: 100px;
  min-width: 100px;
  top: 2%;
  right: 85%;
  text-align: center;
  border-radius: 50px;
`;

export const BoxTelefoneText = styled.div`
  position: absolute;
  background-color: #c9e9ec;
  height: 13%;
  width: 25%;
  margin-top: 17%;
  right: 8%;
  border-radius: 10px;
  text-align: center;
`;

export const BoxTelefoneTextMargin = styled.div`
  height: 100%;
  width: 80%;
  margin-left: 13%;
  border-radius: 10px;
  margin-top: 7%;
`;

export const BoxEmail = styled.div`
  position: absolute;
  background-color: #1976d2;
  min-height: 100px;
  min-width: 100px;
  top: 2%;
  right: 85%;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
`;

export const BoxEmailText = styled.div`
  position: absolute;
  background-color: #c9e9ec;
  height: 13%;
  width: 25%;
  margin-top: 25%;
  right: 8%;
  border-radius: 10px;
`;

export const BoxEmailTextMargin = styled.div`
  height: 100%;
  width: 80%;
  margin-left: 15%;
  border-radius: 10px;
  margin-top: 7%;
`;

export const Container5 = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
`;

export const Background2 = styled.img`
  width: min(100%, 150px);
  border-radius: 10px;
  margin-left: 30%;
  margin-top: 15%;
`;

export const ContainerFinalpage = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
`;

export const Container5Buttons = styled.div`
  display: flex;
  margin-left: 20%;
  margin-top: 10%;
  height: 100%;
  width: 100%;
`;

export const ContainerAcesso = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  margin-left: 20%;
  flex-direction: column;
  align-items: center;
  margin-top: 6%;
`;

export const Container5Text = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  text-align: justify;
`;

export const Container5Text2 = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  font-weight: bold;
  right: 70%;
  text-align: justify;
`;

export const ButtonFacebook = styled.button`
  margin-top: 5%;
  margin-left: 7%;
  color: #1976d2;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${facebook});
  background-size: 75% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonYoutube = styled.button`
  margin-top: 5%;
  margin-left: 10%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${youtube});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonLinkedin = styled.button`
  margin-top: 1%;
  margin-left: 14%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${linkedin});
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonInstagram = styled.button`
  width: 2%;
  margin-top: 5%;
  margin-left: 16%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${instagram});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Container5Text3 = styled.div`
  display: flex;
  text-align: start;
  margin-top: 0%;
  font-size: 20px;
  color: black;
  margin-left: 2%;
  font-weight: bold;
  margin-bottom: 5%;
`;

export const ButtonInicio = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  background-color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonEmpresa = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  font-weight: bold;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;
export const ButtonProdutos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonContato = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonVideos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const Background3 = styled.img`
  width: min(100%, 200px);
  height: min(100%, 200px);
  margin-top: 9%;
  margin-left: 15%;
`;

export const Background4 = styled.img`
  width: min(100%, 150px);
  height: min(100%, 150px);
  margin-top: 8.7%;
  margin-left: 3%;
  margin-right: 5%;
`;

export const Carrossel = styled.img`
  position: absolute;
  width: 50px;
  background-color: blue;
`;
