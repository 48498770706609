/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react'
import background from '../../images/background.png'
import logoMarvini from '../../images/logoMarvini.png'
import assistencia from '../../images/assistencia.png'
import frete from '../../images/frete.png'
import icone_assistenciatecnica from '../../images/icone_assistenciatecnica.png'
import icone_certificado from '../../images/icone_certificado.png'
import icone_garantia from '../../images/icone_garantia.png'
import icone_superentrega from '../../images/icone_superentrega.png'
import madeInBrasil from '../../images/madeInBrasil.png'
import certificadoIso from '../../images/certificadoIso.png'
import garantia from '../../images/garantia.png'
import qualidadeDiamante from '../../images/qualidadeDiamante.png'
import './homeApp.css'
import FlagsButton from '../../components/FlagsButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import './homeStyle.scss'
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  CardMedia,
} from '@material-ui/core'
import {
  Container,
  Container2,
  Container3,
  Container4e5,
  Container5,
  Container2Title,
  Container3Title,
  Container3Text,
  Container4Title,
  Container5Text,
  Container5Text2,
  Container5Text3,
  Background,
  Background2,
  LogoClock,
  LogoPagamento,
  LogoQualidade,
  LogoSeguranca,
  Part,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Anchor,
  CustomButton,
  Container4,
  Button2,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ButtonContato,
  ButtonEmpresa,
  ButtonInicio,
  ButtonProdutos,
  ButtonVideos,
  Background3,
  Background4,
  Container4e5Title,
  Container5Buttons,
  ContainerFinalpage,
  ContainerAcesso,
  LogoGarantia,
  LogoIso,
} from './homeEstilos'
import api from '../../services/api'
import { Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import CarrouselComponent from './../../components/CarrouselComponent'
import SliderComponent from './../../components/SliderComponent'
import { Swiper, SwiperSlide } from 'swiper/react'
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import HomePage from '../../components/MobileMarvini/Home/HomeMobile.jsx'

interface teste2 {
  caminho: any
  data: { caminho: string }
}

interface teste3 {
  caminho: any
  data: { caminho: string }
}

interface teste4 {
  caminho: any
  data: { caminho: string }
}

const Home: React.FC = () => {
  const { t } = useTranslation()
  const [lista, setlista] = useState(Array())
  const [carrossel, setCarrossel] = useState(Array())
  const [carrossel2, setCarrossel2] = useState<any>()
  const [carrossel3, setCarrossel3] = useState(Array())
  const [lingua, setLingua] = useState('portugues')
  const { width } = useWindowDimensions()
  const history = useHistory()

  const [control, setControl] = useState(true)

  useEffect(() => {
    if (width > 600) {
      const btnIniciarConversa = document.querySelector('._2W95M')

      btnIniciarConversa.id = 'btn-iniciar-conversa'

      const botaoConversa = document.getElementById('btn-iniciar-conversa')
      const initialTextAnimation = document.getElementById('objetosHome')

      setTimeout(() => {
        initialTextAnimation.style.display = 'none'
      }, 4500)

      botaoConversa?.addEventListener('click', function (e) {
        e.stopPropagation()
        e.preventDefault()

        window.open(
          `https://api.whatsapp.com/send/?phone=5511940768783&text=${t(
            'whatstapp-apiMessage'
          )}&app_absent=0`
        )
      })
    }
  }, [width])

  const sortCarrouselArray = (array: any[]) => {
    let obj: any[] = []
    array.map((item, index) => {
      console.log('sortCarrouselArrayIndex', item)
      if (item) {
        if (index === 0 || index % 5 === 0) {
          obj.push({
            name: item?.nome,
            desc: item?.subtitulo,
            url: `https://api.marvini.com.br/${item?.image1}`,
            id: item?.id_produto,
            children: [],
          })
        } else {
          let childrenObj = {
            name: item?.nome,
            desc: item?.subtitulo,
            url: `https://api.marvini.com.br/${item?.image1}`,
            id: item?.id_produto,
          }
          if (index <= 4) {
            obj[0].children.push(childrenObj)
          } else {
            obj[1].children.push(childrenObj)
          }
        }
      }
    })

    return obj
  }

  useEffect(() => {
    async function exibirImagem() {
      const teste3 = await api.get<teste2[], any>(`imagens/${lingua}`)
      setlista(teste3.data.imagens)
      console.log(teste3.data)
      // console.log(teste3);
    }
    async function imagemCarrossel() {
      const { data } = await api.get<teste2[], any>('produtos/carrossel')
      console.log(data)
      // const sortedObj = sortCarrouselArray(data?.produtos);
      // console.log([sortedArray]);
      setCarrossel(data?.produtos)
    }
    async function imagemCarrossel2() {
      const { data } = await api.get<teste3[], any>('produtos/carrossel2')
      console.log(data)
      // const sortedObj = sortCarrouselArray(data?.produtos);
      // console.log([sortedArray]);
      setCarrossel2(data?.produtos)
    }
    async function imagemCarrossel3() {
      const teste6 = await api.get<teste4[], any>('produtos/carrossel3')
      setCarrossel3(teste6.data.produtos)
      // console.log(teste4)
    }
    if (control) {
      imagemCarrossel()
      imagemCarrossel2()
      imagemCarrossel3()
      setControl(false)
    }
    exibirImagem()
  }, [lingua])
  console.log(lingua)
  console.log(carrossel)

  return width < 600 ? (
    <HomePage />
  ) : (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <div className="slider1">
            <div>
              <>
                <Swiper
                  spaceBetween={10}
                  centeredSlides={true}
                  autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                  }}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={true}
                  style={{
                    width: '100vw',
                    height: '70vh',
                    zIndex: 10,
                  }}
                >
                  {lista.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <img
                          key={index}
                          style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: '100%',
                            width: '100%',
                            backgroundImage: `url(https://api.marvini.com.br/${item?.caminho})`,
                          }}
                        ></img>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </>
            </div>
          </div>
          <div className="botoesInicio">
            <img
              className="btn"
              onClick={() => history.push('/')}
              style={{
                width: '180px',
                height: 'auto',
                position: 'absolute',
                left: '70px',
                top: '-30%',
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push('/empresa')}>
              {t('btnEmpresa')}
            </button>
            <button className="btn" onClick={() => history.push('/produtos')}>
              {t('btnProduto')}
            </button>
            <button className="btn" onClick={() => history.push('/contato')}>
              {t('btnContato')}
            </button>
            <button className="btn" onClick={() => history.push('/videos')}>
              {t('btnVideos')}
            </button>
          </div>

          <div
            className="Valoriza"
            style={{
              fontSize: '20px',
              position: 'absolute',
              right: '30px',
              top: '110px',
            }}
          >
            {t('marvini')} <b>{t('valoriza')}</b> {t('seuCarro')}
          </div>
          <div id="objetosHome" className="objetosHome">
            <h1
              style={{
                fontSize: '60px',
                fontFamily: 'monospace',
                position: 'absolute',
                top: '50%',
                textTransform: 'uppercase',
              }}
            >
              {t('bemVindo')} <br /> MARVINI
            </h1>
          </div>
          <div
            className="whats"
            style={{
              position: 'absolute',
              bottom: '-3%',
              right: '1%',
              height: '150px',
              width: '150px',
              zIndex: 999,
            }}
          >
            <WhatsAppWidget
              textReplyTime={t('whatsapp-messageTextReplyTime')}
              companyName="Marvini"
              message={t('whatsapp-message')}
              sendButton={t('whatsapp-sendButton')}
            />
          </div>
          <Box
            className="seta"
            sx={(theme) => ({
              position: 'absolute',
              bottom: '-30%',
              right: '38.5%',
              zIndex: 999,

              [theme.breakpoints.up('xl')]: {
                bottom: '-15%',
              },
            })}
          >
            <img
              src="../../images/seta.png"
              style={{
                width: '50px',
                height: '30px',
              }}
            />
          </Box>
          <FlagsButton setLingua={setLingua} />
        </div>
      </header>

      <Anchor className="barraitens">
        <div
          className="partDiv"
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <LogoClock
            style={{
              marginLeft: '15%',
              marginBottom: '1.9rem',
              marginTop: '-2rem',
              height: '45px',

              objectFit: 'contain',
            }}
            src={icone_superentrega}
          />
          <Part>
            <span>{t('EntregaRapida')}</span>
            <br />
            <br />
            {t('EntregaRapidaDescript')}
          </Part>
        </div>
        <div className="partDiv">
          <LogoPagamento
            style={{
              marginBottom: '0.7rem',
              marginTop: '-3rem',
              height: '80px',

              objectFit: 'fill',
            }}
            src={madeInBrasil}
          ></LogoPagamento>
          <Part2>
            <span>{t('PagamentoSeguro')}</span>
            <br />
            <br />
            {t('PagamentoSeguroDescript')}
          </Part2>
        </div>
        <div className="partDiv">
          <LogoQualidade
            style={{
              marginBottom: '1.1rem',
              marginTop: '-2rem',
              height: '55px',
              objectFit: 'contain',
            }}
            src={icone_assistenciatecnica}
          ></LogoQualidade>
          <Part3>
            <span>{t('MelhorQualidade')}</span>
            <br />
            <br />
            {t('MelhorQualidadeDescript')}
          </Part3>
        </div>
        <div className="partDiv">
          <LogoSeguranca
            style={{
              marginBottom: '0.32rem',
              marginTop: '-2.2rem',
              height: '70px',
              objectFit: 'contain',
            }}
            src={qualidadeDiamante}
          ></LogoSeguranca>
          <Part7>
            <span>{t('RetornoGarantido')}</span>
            <br />
            <br />
            {t('RetornoGarantidoDescript')}
          </Part7>
        </div>
        <div className="partDiv">
          <LogoGarantia
            style={{
              marginBottom: '1rem',
              marginTop: '-2rem',
              height: '55px',
              objectFit: 'contain',
            }}
            src={icone_garantia}
          ></LogoGarantia>
          <Part4>
            <span>{t('RetornoGarantido2')}</span>
            <br />
            <br />
            {t('RetornoGarantidoDescript2')}
          </Part4>
        </div>
        <div className="partDiv">
          <LogoIso
            style={{
              marginBottom: '1rem',
              marginTop: '-2rem',
              height: '55px',
              objectFit: 'contain',
            }}
            src={icone_certificado}
          ></LogoIso>
          <Part6>
            <span>{t('RetornoGarantido3')}</span>
            <br />
            <br />
            {t('RetornoGarantidoDescript3')}
          </Part6>
        </div>
      </Anchor>
      <Container2>
        <Container2Title>
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0a0a60, #0e77ee)',
              height: 8,
              width: 360,
              marginRight: 14,
            }}
          />
          {t('Destaques')}
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0e77ee, #0a0a60)',
              height: 8,
              width: 360,
              marginLeft: 14,
            }}
          />
        </Container2Title>
        <Box
          sx={(theme) => ({
            marginTop: '1%',

            [theme.breakpoints.up('xl')]: { marginLeft: '290px' },
          })}
        >
          {carrossel?.length === 0 ? (
            false
          ) : (
            <CarrouselComponent array={carrossel} />
          )}
        </Box>
      </Container2>
      <Container2>
        <Container2Title>
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0a0a60, #0e77ee)',
              height: 8,
              width: 360,
              marginRight: 14,
            }}
          />
          {t('top10')}
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0e77ee, #0a0a60)',
              height: 8,
              width: 360,
              marginLeft: 14,
            }}
          />
        </Container2Title>
        <Box
          sx={(theme) => ({
            marginTop: '1%',

            [theme.breakpoints.up('xl')]: { marginLeft: '290px' },
          })}
        >
          {carrossel2?.length === 0 ? (
            false
          ) : (
            <CarrouselComponent array={carrossel2} />
          )}
        </Box>
      </Container2>
      <Container3>
        <div>
          <Container3Title>{t('sobreMarvini')}</Container3Title>
          <Container3Text>{t('textoSobreMarvini')}</Container3Text>
          <CustomButton onClick={() => history.push('/empresa')}>
            {t('saibaMais')}
          </CustomButton>
        </div>
        <div>
          <Background src={background}></Background>
        </div>
      </Container3>
      <Container4>
        <Container4Title>
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0a0a60, #0e77ee)',
              height: 8,
              width: 360,
              marginRight: 14,
            }}
          />
          {t('nossosProdutos')}
          <div
            style={{
              backgroundImage: 'linear-gradient(to right, #0e77ee, #0a0a60)',
              height: 8,
              width: 250,
              marginLeft: 14,
            }}
          />
          <Button2 onClick={() => history.push('/produtos')}>
            {t('verTodos')}
          </Button2>
        </Container4Title>
      </Container4>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',

          [theme.breakpoints.up('xl')]: {
            marginTop: '-20px',
          },
        })}
      >
        {carrossel3.length < 5 ? (
          carrossel3.map((item, index) => {
            return (
              <Box
                key={index}
                sx={(theme) => ({
                  display: 'inline-block',
                  margin: '-120px 20px 0px 20px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',

                  [theme.breakpoints.up('xl')]: {
                    margin: '-250px 20px 0px 20px',
                  },
                })}
              >
                <Card
                  sx={{
                    width: '300px',
                    maxHeight: '300px',
                    boxShadow: 'none',
                  }}
                >
                  <CardContent>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 20,
                        wordBreak: 'break-word',
                      }}
                    >
                      {item?.nome}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    sx={{
                      objectFit: 'cover',
                      height: '100px',
                      width: '50%',
                      margin: 'auto',
                      padding: '10px 0',
                    }}
                    image={`https://api.marvini.com.br/${item?.image1}`}
                  />
                </Card>
              </Box>
            )
          })
        ) : (
          <SliderComponent array={carrossel3} />
        )}
      </Box>
      {/* <Container4e5>
        <div>
          <Container4e5Title>{t("comentarioClientes")}</Container4e5Title>
        </div>
        <div className="containerComents">
          <input type="radio" name="slider" id="item-1" />
          <input type="radio" name="slider" id="item-2" checked />
          <input type="radio" name="slider" id="item-3" />
          <div className="cardsComents">
            <label className="cartaComents" htmlFor="item-1" id="song-1">
              <img
                className="cardImage"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC"
                alt="song"
              />
              <img
                className="profileStars"
                src="https://cdn-icons-png.flaticon.com/512/991/991986.png"
                alt="stars"
              />
              <figcaption>{t("comentarioTexto")}</figcaption>
              <label className="texto">{t("autor")}</label>
              <label className="texto2">{t("empresaAutor")}</label>
              <img
                className="profileImage"
                src="https://cdn-icons-png.flaticon.com/128/3011/3011270.png"
                alt="profile"
              />
            </label>
            <label className="cartaComents" htmlFor="item-2" id="song-2">
              <img
                className="cardImage"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC"
                alt="song"
              />
              <img
                className="profileStars"
                src="https://cdn-icons-png.flaticon.com/512/991/991986.png"
                alt="stars"
              />
              <figcaption>{t("comentarioTexto2")}</figcaption>
              <label className="texto">{t("autor2")}</label>
              <label className="texto2">{t("empresaAutor2")}</label>
              <img
                className="profileImage"
                src="https://cdn-icons-png.flaticon.com/512/3135/3135768.png"
                alt="profile"
              />
            </label>
            <label className="cartaComents" htmlFor="item-3" id="song-3">
              <img
                className="cardImage"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwDcaiAAFXD1ujAAAAAElFTkSuQmCC"
                alt="song"
              />
              <img
                className="profileStars"
                src="https://cdn-icons-png.flaticon.com/512/991/991986.png"
                alt="stars"
              />
              <figcaption>{t("comentarioTexto3")}</figcaption>
              <label className="texto">{t("autor3")}</label>
              <label className="texto2">{t("empresaAutor3")}</label>
              <img
                className="profileImage"
                src="https://cdn-icons-png.flaticon.com/128/236/236831.png"
                alt="profile"
              />
            </label>
          </div>
          <div className="upper-part"></div>
        </div>
      </Container4e5> */}
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          {/* <Container5Text>{t("descricaoFim")}</Container5Text> */}
          <Container5Text2>{t('nosSiga')}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t('acessoRapido')}</Container5Text3>
          <ButtonInicio onClick={() => history.push('/')}>
            {t('inicio')}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push('/empresa')}>
            {t('empresa')}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push('/produtos')}>
            {t('produtos')}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push('/contato')}>
            {t('contato')}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push('/videos')}>
            {t('videos')}
          </ButtonVideos>
        </ContainerAcesso>
        <Background3 src={garantia}></Background3>
        <Background4 src={certificadoIso}></Background4>
      </Container5>
    </div>
  )
}

export default Home
