import Routes from "./routes";
import "../src/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export { App };
