import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import routes from './routes';
import ScrollTop from './ScrollToTop';

const Routes = () => {
  return (
    <Router basename="">
      <ScrollTop />
      <Switch>
        {routes.public.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            component={() => <route.component />}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default Routes;
