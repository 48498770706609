import Slider from "react-slick";
import { Card, CardContent } from "@material-ui/core";

const SliderComponent = ({ array }) => {
  var settings = {
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 500,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
  };

  return (
    <div
      style={{
        margin: "10px auto 50px",
        boxShadow: "0 10px 20px -5px rgba(0, 0, 0, 0.125)",
        width: "100%",
        marginBottom: "1%",
        marginTop: "-130px",
      }}
    >
      <Slider {...settings}>
        {array?.map((item, k) => {
          return (
            <div key={k}>
              <Card
                sx={{
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <h3
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bold",
                      wordBreak: "break-word",
                      marginBottom: -4,
                    }}
                  >
                    {item?.nome}
                  </h3>
                </CardContent>
                <img
                  style={{
                    objectFit: "cover",
                    margin: "auto",
                    height: "200px",
                    padding: "10px 0px",
                  }}
                  src={`https://api.marvini.com.br/${item?.image1}`}
                />
              </Card>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default SliderComponent;
