import axios from 'axios'
//produção, url
const api = axios.create({ 
  baseURL: 'https://api.marvini.com.br/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
})

export default api
