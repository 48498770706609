/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import logoMarvini from "../../images/logoMarvini.png";
import FlagsButton from "../../components/FlagsButton";
import certificadoIso from "../../images/certificadoIso.png";
import garantia from "../../images/garantia.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "./App.css";
import "./empresaStyle.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import LanguageIcon from "@mui/icons-material/Language";
import $ from "jquery";
import { Grid } from "@mui/material";
import {
  App,
  Container,
  Container2,
  Header,
  TitleHome,
  Container5,
  Container5Text,
  Container5Text2,
  Container5Text3,
  Background2,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ButtonContato,
  ButtonEmpresa,
  ButtonInicio,
  ButtonProdutos,
  ButtonVideos,
  Background3,
  Background4,
  ContainerFinalpage,
  Container5Buttons,
  ContainerAcesso,
} from "./styles";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Company from "../../components/MobileMarvini/Company";

$(document).ready(function () {
  // eslint-disable-next-line no-undef
  $(".counter").each(function () {
    $(this)
      .prop("Counter", 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 4000,
          easing: "swing",
          step: function (now) {
            $(this).text(Math.ceil(now));
          },
        }
      );
  });
});

const contato: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { width } = useWindowDimensions();

  return width < 600 ? (
    <Company />
  ) : (
    <App>
      <Header>
        <Container>
          <div className="botoesInicio">
          <img
              className="btn"
              onClick={() => history.push("/")}
              style={{
                width: "180px",
                height: "auto",
                position: "absolute",
                left: "70px",
                top: "-30%",
                backgroundColor: "white",
                borderRadius: "100px",
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push("/empresa")}>
              {t("btnEmpresa")}
            </button>
            <button className="btn" onClick={() => history.push("/produtos")}>
              {t("btnProduto")}
            </button>
            <button className="btn" onClick={() => history.push("/contato")}>
              {t("btnContato")}
            </button>
            <button className="btn" onClick={() => history.push("/videos")}>
              {t("btnVideos")}
            </button>
          </div>

          <div
            className="legendaTitle"
            style={{
              fontSize: "20px",
              position: "absolute",
              right: "30px",
              top: "110px",
            }}
          >
            {t("marvini")} <b>{t("valoriza")}</b> {t("seuCarro")}
          </div>
          <TitleHome>
            {t("EMPRESAtitle")}
            <br />―
          </TitleHome>
          <FlagsButton setLingua={() => {}} />
        </Container>
      </Header>
      <Container2>
        <div className="contadores">
          <SettingsIcon
            className="icones"
            sx={(theme) => ({
              position: "absolute",
              marginLeft: "11.3%",
              marginTop: "0%",
              fontSize: "300%",
              backgroundColor: "white",

              [theme.breakpoints.down("md")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },

              [theme.breakpoints.down("md")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },
            })}
          />
          <div className="four col-md-3">
            <div className="counter-box colored">
              <div className="teste9">
                <p>+</p>
                <span className="counter">60</span>
              </div>
            </div>
            <b>{t("LINHASdePRODUTO")}</b>
          </div>

          <ExtensionIcon
            className="icones"
            sx={(theme) => ({
              position: "absolute",
              marginLeft: "11.3%",
              marginTop: "0%",
              fontSize: "300%",
              backgroundColor: "white",

              [theme.breakpoints.down("md")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },

              [theme.breakpoints.down("sm")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },
            })}
          />
          <div className="four col-md-3">
            <div className="counter-box">
              <div className="teste9">
                <p>+</p>
                <span className="counter">2500</span>
              </div>
            </div>
            <b>{t("PRODUTOS")}</b>
          </div>
          <LanguageIcon
            className="icones"
            sx={(theme) => ({
              position: "absolute",
              marginLeft: "11.3%",
              marginTop: "0%",
              fontSize: "300%",
              backgroundColor: "white",

              [theme.breakpoints.down("md")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },

              [theme.breakpoints.down("sm")]: {
                position: "relative",
                backgroundColor: "white",
                marginLeft: "11.3%",
                marginTop: "0%",
                fontSize: "300%",
              },
            })}
          />
          <div className="four col-md-3">
            <div className="counter-box">
              <div className="teste9">
                <p>+</p>
                <span className="counter">10</span>
              </div>
            </div>
            <b>{t("PAISES")}</b>
          </div>
        </div>

        <div className="wrapper">
          <div className="slideshows">
            <div className="slideshow slideshow--hero">
              <div className="slides">
                <div className="slide slide1"></div>
                <div className="slide slide2"></div>
                <div className="slide slide3"></div>
              </div>
            </div>
            <div className="slideshow slideshow--contrast slideshow--contrast--before">
              <div className="slides">
                <div className="slide slide1"></div>
                <div className="slide slide2"></div>
                <div className="slide slide3"></div>
              </div>
            </div>
            <div className="slideshow slideshow--contrast slideshow--contrast--after">
              <div className="slides">
                <div className="slide slide1"></div>
                <div className="slide slide2"></div>
                <div className="slide slide3"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="textoEmpresa">{t("textoSobreEmpresa")}</div>
      </Container2>
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          {/* <Container5Text>{t("descricaoFim")}</Container5Text> */}
          <Container5Text2>{t("nosSiga")}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t("acessoRapido")}</Container5Text3>
          <ButtonInicio onClick={() => history.push("/")}>
            {t("inicio")}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push("/empresa")}>
            {t("empresa")}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push("/produtos")}>
            {t("produtos")}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push("/contato")}>
            {t("contato")}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push("/videos")}>
            {t("videos")}
          </ButtonVideos>
        </ContainerAcesso>
        <div className="selos">
          <Background3 src={garantia}></Background3>
          <Background4 src={certificadoIso}></Background4>
        </div>
      </Container5>
    </App>
  );
};

export default contato;
