import React, { useState } from "react";
import { Button, Container } from "./styles";
import { useTranslation } from "react-i18next";

interface WrapperProps extends React.HTMLAttributes<HTMLButtonElement> {
  setLingua: Function;
}

const FlagsButton: React.FC<WrapperProps> = ({ setLingua }) => {
  const { i18n } = useTranslation();

  return (
    <Container>
      <Button
        onClick={() => {
          i18n.changeLanguage("pt");
          setLingua("portugues");
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/330/330430.png"
          width="50"
          height="50"
          alt=""
        ></img>
      </Button>
      <Button
        onClick={() => {
          i18n.changeLanguage("en");
          setLingua("ingles");
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/330/330451.png"
          width="50"
          height="50"
          alt=""
        ></img>
      </Button>
      <Button
        onClick={() => {
          i18n.changeLanguage("es");
          setLingua("espanhol");
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/330/330557.png"
          width="50"
          height="50"
          alt=""
        ></img>
      </Button>
    </Container>
  );
};
export default FlagsButton;
