import { useState } from 'react'
import MobileHeader from '../../MobileHeader'
import MobileFooter from '../../MobileFooter'
import { useTranslation } from 'react-i18next'
import { representantes } from '../../../utils'
import iconePredio from '../../../images/apartamento.svg'
import iconeTelefone from '../../../images/telefone-icon.svg'
import iconeEmail from '../../../images/email-icon.svg'
import Profile from '../../../components/representantes/home'
import Nome from '../../../components/representantes/nome'
import Email from '../../../components/representantes/email'
import Telefone from '../../../components/representantes/telefone'
import Local from '../../../components/representantes/localizacao'
import './contacts.css'

export default function Contacts() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [showSideBar, setShowSideBar] = useState(false)
  const [lingua, setLingua] = useState('portugues')

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="contacts-bg">
        <span>{t('contato')}</span>
        <span style={{ fontSize: '53px', marginTop: '-20px' }}>-</span>
      </div>
      <div className="contacts-container">
        <div className="representantes-box">
          <span>Representantes</span>
        </div>
        <div className="representantes-scroll">
          {representantes?.map((item) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="card-representantes">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                    >
                      <Profile />
                      <span>{item?.empresa}</span>
                    </div>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                    >
                      <Local />
                      <span
                        style={{
                          display: 'flex',
                        }}
                      >
                        {item?.localizacao}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Nome />
                    <span>{item?.nome}</span>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Email />
                    <span>{item?.email}</span>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Telefone />
                    <span>{item?.telefone}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="info-container">
        <div className="info-card">
          <img src={iconePredio} width={60} height={60} />
          <span>Rua João Paulo Ablas 360, galpão N°2, Jardim da Gloria.</span>
          <span>Brasil - São Paulo - Cotia [CEP 06711-250]</span>
        </div>
        <div className="info-card">
          <img src={iconeTelefone} width={60} height={60} />
          <span>+55 (11) 4777-1354</span>
          <span>+55 (11) 94076-8783</span>
        </div>
        <div className="info-card">
          <img src={iconeEmail} width={60} height={60} />
          <span>vendas@vinici.com.br</span>
          <span>vinici@vinici.com.br</span>
        </div>
      </div>
      <MobileFooter t={t} />
    </div>
  )
}
