import certificadoIso from "../../images/certificadoIso.png";
import garantia from "../../images/garantia.png";
import facebook from "../../images/footerMobile/facebook-mobile.svg";
import youtube from "../../images/footerMobile/youtube-mobile.svg";
import linkedin from "../../images/footerMobile/linkedin-mobile.svg";
import instagram from "../../images/footerMobile/instagram-mobile.svg";
import { useHistory } from "react-router-dom";
import "./styles.css";

function MobileFooter({ t }) {
  const history = useHistory();

  return (
    <div className="footer-mobile">
      <div className="footer-title">{t("acessoRapido")}</div>
      <div className="footer-main">
        <div className="footer-div-img">
          <img src={certificadoIso} alt="" className="footer-img-body" />
        </div>
        <div className="footer-div-span">
          <span onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
            {t("inicio")}
          </span>
          <span
            onClick={() => history.push("/empresa")}
            style={{ cursor: "pointer" }}
          >
            {t("empresa")}
          </span>
          <span
            onClick={() => history.push("/produtos")}
            style={{ cursor: "pointer" }}
          >
            {t("produtos")}
          </span>
          <span
            onClick={() => history.push("/contato")}
            style={{ cursor: "pointer" }}
          >
            {t("contato")}
          </span>
          <span
            onClick={() => history.push("/videos")}
            style={{ cursor: "pointer" }}
          >
            {t("videos")}
          </span>
        </div>
        <div className="footer-div-img">
          <img src={garantia} alt="" className="footer-img-body" />
        </div>
      </div>
      <div className="footer-img-icons">
        <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
          <img src={youtube} alt="Botão para o Youtube da Marvini" />
        </a>
        <a href="https://www.instagram.com/marvini.automotivo/">
          <img src={instagram} alt="Botão para o Instagram da Marvini" />
        </a>
        <img
          onClick={() => history.push("/")}
          src="/LogoMarvini.png"
          alt="Logo da Marvini"
          style={{ maxWidth: "51px", maxHeight: "51px", cursor: "pointer" }}
        />
        <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
          <img src={linkedin} alt="Botão para o Linkedin da Marvini" />
        </a>
        <a href="https://www.facebook.com/marvini.automotivo">
          <img src={facebook} alt="Botão para o Facebook da Marvini" />
        </a>
      </div>
    </div>
  );
}

export default MobileFooter;
