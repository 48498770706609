import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";
import "./swiper-bundle.css";
import "./styles.css";
import api from "../../services/api";

interface ILista {
  caminho: any;
  data: { caminho: string };
}

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);

const SwiperPage: React.FC = () => {
  const [lista, setLista] = useState<ILista[]>([]);
  const [lingua, setLingua] = useState("portugues");

  useEffect(() => {
    getImages();
  }, [lingua]);

  const getImages = async () => {
    try {
      const response = await api.get<ILista[], any>(`imagens/${lingua}`);
      setLista(response.data.imagens);
    } catch (e) {
      console.log(e);
    }
  };
  console.log(lista);
  return (
    <div>
      <>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          className="mySwiper"
          style={{
            width: "100vw",
            height: "70vh",
          }}
        >
          {lista.map((item, index) => {
            return (
              <SwiperSlide>
                <img
                  key={index}
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100%",
                    width: "100%",
                    backgroundImage: `url(https://api.marvini.com.br/${item?.caminho})`,
                  }}
                ></img>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    </div>
  );
};
export default SwiperPage;
