/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import Local from "../../components/representantes/localizacao";
import Email from "../../components/representantes/email";
import Telefone from "../../components/representantes/telefone";
import Profile from "../../components/representantes/home";
import Nome from "../../components/representantes/nome";
import atendimento from "../../images/atendimento.png";
import predio from "../../images/predio.png";
import telefone from "../../images/telefone.png";
import email from "../../images/email.png";
import gps from "../../images/localização.png";
import logoMarvini from "../../images/logoMarvini.png";
import FlagsButton from "../../components/FlagsButton";
import certificadoIso from "../../images/certificadoIso.png";
import garantia from "../../images/garantia.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Contacts from "../../components/MobileMarvini/Contacts/Contacts";
import "./App.css";

import {
  App,
  Container,
  Container2,
  Header,
  TitleHome,
  TitleContato,
  BoxAtendimento,
  BoxPredio,
  BoxPredioText,
  BoxTelefone,
  BoxTelefoneText,
  BoxEmail,
  BoxEmailText,
  Image,
  ImageBox,
  TextContato,
  Container5,
  Container5Text,
  Container5Text2,
  Container5Text3,
  Gps,
  ImageBox1,
  Container3Pontos,
  Background2,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ButtonContato,
  ButtonEmpresa,
  ButtonInicio,
  ButtonProdutos,
  ButtonVideos,
  Background3,
  Background4,
  BoxTelefoneTextMargin,
  BoxEmailTextMargin,
  BoxPredioTextMargin,
  ContainerFinalpage,
  Container5Buttons,
  ContainerAcesso,
} from "./styles";

const contato: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { width } = useWindowDimensions();

  return width < 600 ? (
    <Contacts />
  ) : (
    <App>
      <Header>
        <Container>
          <div className="botoesInicio">
            <img
              className="btn"
              onClick={() => history.push("/")}
              style={{
                width: "180px",
                height: "auto",
                position: "absolute",
                left: "70px",
                top: "-30%",
                backgroundColor: "white",
                borderRadius: "100px",
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push("/empresa")}>
              {t("btnEmpresa")}
            </button>
            <button className="btn" onClick={() => history.push("/produtos")}>
              {t("btnProduto")}
            </button>
            <button className="btn" onClick={() => history.push("/contato")}>
              {t("btnContato")}
            </button>
            <button className="btn" onClick={() => history.push("/videos")}>
              {t("btnVideos")}
            </button>
          </div>

          <div
            style={{
              fontSize: "20px",
              position: "absolute",
              right: "30px",
              top: "110px",
            }}
          >
            {t("marvini")} <b>{t("valoriza")}</b> {t("seuCarro")}
          </div>
          <TitleHome>
            {t("contatoTitle")}
            <br />―
          </TitleHome>
          <FlagsButton setLingua={() => {}} />
        </Container>
      </Header>
      <Container2>
        {/* <BoxAtendimento>
          <Image src={atendimento}></Image>
          <TitleContato>{t("vamosConversar")}</TitleContato>
          <TextContato>{t("contatoTexto")}</TextContato>
        </BoxAtendimento> */}
        {/* <Container3Pontos>
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
        </Container3Pontos> */}
        <span className="spantable">Representantes</span>
        <div className="divtable">
          <table className="table">
            <tbody className="tabelabody">
              <br />
              <div className="iconesestado">
                <div>
                  <Local></Local>
                </div>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>A F DE PAULA EIRELI-ME</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Alírio</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>alirio.representacoes@gmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(11) 94013-4288</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>CAROLINA RODRIGUES COELHO REPRESENTAÇOES - ME</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Denílson</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>kce@terra.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(11) 94736-0504</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>COREPE REPRESENTACOES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Renato</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>corepe@uol.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(14) 3224-1222/98118-1605</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>JULIANA GOMES ANDREOTTI 45153690830</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Jacyra</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>jgomes97@hotmail.com/jgomes@globo.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(11) 94474-6774</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>KAJU REPRESENTACOES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Toninho</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>toninhokaju@uol.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(11)5505-6892/99931-0234</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>LATTIMO VEND REPRESENTAÇÃO COMERCIAL LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Antonio Luiz</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>antoniol@lattimo.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(11) 98304-5984</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>CE/TO/MA</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>ANDREIA DE SOUSA ROMERO 67244289391</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Ricardo</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>ricardoromeiro3r@gmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(85) 99999-1189/98730-1717</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>ES</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>BHB REPRESENTACOES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Marcos</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>bhb@bhbrepresentacoes.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(27)3329-0601/99274-8723</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>RJ</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>A.S.LIMA PROMOÇÕES COMERCIAIS LTDA-ME</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Bruno</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>aslima@aslima.com.br/buno@aslima.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(21) 2589-1473/98882-3113</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>PA/AP</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>A J M LIMA REP EIRELI</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Afonso</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>afonsojmlima@hotmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(91) 98162-2787</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>RO/AC/RR/AM</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>BIATA COMERCIO E REPRESENTAÇÕES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Tatiana</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>tati@biata.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(69) 3222-4958/(69) 99981-0639</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>MS</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>Damasceno Representações LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Damasceno</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>dama@damasceno.com.br/vendas@damasceno.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(67) 3029-0040/(67) 99207-4058</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>RS</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>BREKAPEÇAS COMERCIO E REPRESENTAÇÕES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Fernando</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>contato@brekapecas.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(51) 3341-8579/99983-0427</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>PR</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>HELIO CARLOS GONCALVES - ME</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Hélio</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>heliocarlosgoncalves@hotmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(41) 99540-5655</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>PB/RN</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>IRINALDO MARCOLINO DA SILVA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Irinaldo</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>irinaldorep.pb@hotmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(83) 99647-0164</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>BA</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>J.P BARRETO REPRESENTAÇÃO COMERCIAL LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Juarez</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>juarezvendasbahia@gmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(71)99988-0100</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local> <td>SE</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>JVL COMERCIO E REPRESENTACOES DE AUTO PECAS LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Lucas/Luiz</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>lucas@lasantos.com/luiz@lasantos.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(79) 98831-1100/(79) 98835-5110</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>PE</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>LK REPRES. DE PEÇAS E ACESS. P/ VEICULOS LTDA-ME</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Kátia</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>meta_rep@hotmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(81) 3083-3072/99113-4221</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>GO</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>MARIA ABADIA BORGES DE SOUZA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Abadia</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>acmrep@outlook.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>((62) 3259-1249/98531-1000</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>MG</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>PARTNER ASSESSORIA E REPRESENTAÇÃO LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Fernanda/Cassio</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>partner@partnermg.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(31)3088-6540/(31) 99615-9315/(31) 98448-5724</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>DF</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>RIVALDO BEZERRA PRIMO</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Rivaldo</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>rivaldo2930@gmail.com</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(61) 98408-7736</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>SC</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>VIGOKAR COMERCIO E REPRESENTÇÕES LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Wilson</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>vigokar@terra.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(47) 99991-8280</tr>
              </div>
              <br />
              <div className="iconesestado">
                <Local></Local>
                <td>EXPORTAÇÃO</td>
              </div>
              <div className="icones">
                <Profile></Profile>
                <tr>Comercio e Representações Faros LTDA</tr>
              </div>
              <div className="icones">
                <Nome></Nome>
                <tr>Luiz</tr>
              </div>
              <div className="icones">
                <Email></Email>
                <tr>faroscom@uol.com.br</tr>
              </div>
              <div className="icones">
                <Telefone></Telefone>
                <tr>(48) 3266-6079/(48) 99983-6012</tr>
              </div>
            </tbody>
          </table>
        </div>
        <BoxPredioText>
          <BoxPredio>
            <ImageBox src={predio}></ImageBox>
          </BoxPredio>
          <BoxPredioTextMargin>
            {t("enderecoTexto")}
            <br />
            <br />
            {t("enderecoTexto2")}
          </BoxPredioTextMargin>
        </BoxPredioText>
        <BoxTelefoneText>
          <BoxTelefone>
            <ImageBox src={telefone}></ImageBox>
          </BoxTelefone>
          <BoxTelefoneTextMargin>
            {t("telefoneTexto")}
            <br />
            <br />
            {t("telefoneTexto2")}
          </BoxTelefoneTextMargin>
        </BoxTelefoneText>
        <BoxEmailText>
          <BoxEmail>
            <ImageBox src={email}></ImageBox>
          </BoxEmail>
          <BoxEmailTextMargin>
            {t("emailTexto")}
            <br />
            <br />
            {t("emailTexto2")}
          </BoxEmailTextMargin>
        </BoxEmailText>
      </Container2>
      <Gps>
        <ImageBox1 src={gps}></ImageBox1>
      </Gps>
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          {/* <Container5Text>{t("descricaoFim")}</Container5Text> */}
          <Container5Text2>{t("nosSiga")}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="https://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t("acessoRapido")}</Container5Text3>
          <ButtonInicio onClick={() => history.push("/")}>
            {t("inicio")}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push("/empresa")}>
            {t("empresa")}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push("/produtos")}>
            {t("produtos")}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push("/contato")}>
            {t("contato")}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push("/videos")}>
            {t("videos")}
          </ButtonVideos>
        </ContainerAcesso>
        <Background3 src={garantia}></Background3>
        <Background4 src={certificadoIso}></Background4>
      </Container5>
    </App>
  );
};

export default contato;
