export const representantes = [
  {
    empresa: "A F DE PAULA EIRELI-ME",
    nome: "Alírio",
    email: "alirio.representacoes@gmail.com",
    telefone: "(11) 94013-4288",
    localizacao: "SP",
  },
  {
    empresa: "CAROLINA RODRIGUES COELHO REPRESENTAÇOES - ME",
    nome: "Denílson",
    email: "kce@terra.com.br",
    telefone: "(11) 94736-0504",
    localizacao: "SP",
  },
  {
    empresa: "COREPE REPRESENTACOES LTDA",
    nome: "Renato",
    email: "corepe@uol.com.br",
    telefone: "(14) 3224-1222 / 98118-1605",
    localizacao: "SP",
  },
  {
    empresa: "JULIANA GOMES ANDREOTTI 45153690830",
    nome: "Jacyra",
    email: "jgomes97@hotmail.com / jgomes@globo.com",
    telefone: "(11) 94474-6774",
    localizacao: "SP",
  },
  {
    empresa: "KAJU REPRESENTACOES LTDA",
    nome: "Toninho",
    email: "toninhokaju@uol.com.br",
    telefone: "(11) 5505-6892 / 99931-0234",
    localizacao: "SP",
  },
  {
    empresa: "LATTIMO VEND REPRESENTAÇÃO COMERCIAL LTDA",
    nome: "Antonio Luiz",
    email: "antoniol@lattimo.com.br",
    telefone: "(11) 98304-5984",
    localizacao: "SP",
  },
  {
    empresa: "ANDREIA DE SOUSA ROMERO 67244289391",
    nome: "Ricardo",
    email: "ricardoromeiro3r@gmail.com",
    telefone: "(85) 99999-1189 / 98730-1717",
    localizacao: "CE / TO / MA",
  },
  {
    empresa: "BHB REPRESENTACOES LTDA",
    nome: "Marcos",
    email: "bhb@bhbrepresentacoes.com.br",
    telefone: "(27)3329-0601 / 99274-8723",
    localizacao: "ES",
  },
  {
    empresa: "A.S.LIMA PROMOÇÕES COMERCIAIS LTDA-ME",
    nome: "Bruno",
    email: "aslima@aslima.com.br/buno@aslima.com.br",
    telefone: "(21) 2589-1473 / 98882-3113",
    localizacao: "RJ",
  },
  {
    empresa: "A J M LIMA REP EIRELI",
    nome: "Afonso",
    email: "afonsojmlima@hotmail.com",
    telefone: "(91) 98162-2787",
    localizacao: "PA / AP",
  },
  {
    empresa: "BIATA COMERCIO E REPRESENTAÇÕES LTDA",
    nome: "Tatiana",
    email: "tati@biata.com.br",
    telefone: "(69) 3222-4958 / (69) 99981-0639",
    localizacao: "RO / AC / RR / AM",
  },
  {
    empresa: "Damasceno Representações LTDA",
    nome: "Damasceno",
    email: "dama@damasceno.com.br / vendas@damasceno.com.br",
    telefone: "(67) 3029-0040 / (67) 99207-4058",
    localizacao: "MS",
  },
  {
    empresa: "BREKAPEÇAS COMERCIO E REPRESENTAÇÕES LTDA",
    nome: "Fernando",
    email: "contato@brekapecas.com.br",
    telefone: "(51) 3341-8579 / 99983-0427",
    localizacao: "RS",
  },
  {
    empresa: "HELIO CARLOS GONCALVES - ME",
    nome: "Hélio",
    email: "heliocarlosgoncalves@hotmail.com",
    telefone: "(41) 99540-5655",
    localizacao: "PR",
  },
  {
    empresa: "IRINALDO MARCOLINO DA SILVA",
    nome: "Irinaldo",
    email: "irinaldorep.pb@hotmail.com",
    telefone: "(83) 99647-0164",
    localizacao: "PB / RN",
  },
  {
    empresa: "J.P BARRETO REPRESENTAÇÃO COMERCIAL LTDA",
    nome: "Juarez",
    email: "juarezvendasbahia@gmail.com",
    telefone: "(71) 99988-0100",
    localizacao: "BA",
  },
  {
    empresa: "JVL COMERCIO E REPRESENTACOES DE AUTO PECAS LTDA",
    nome: "Lucas / Luiz",
    email: "lucas@lasantos.com / luiz@lasantos.com",
    telefone: "(79) 98831-1100 / (79) 98835-5110",
    localizacao: "SE",
  },
  {
    empresa: "LK REPRES. DE PEÇAS E ACESS. P/ VEICULOS LTDA-ME",
    nome: "Kátia",
    email: "meta_rep@hotmail.com",
    telefone: "(81) 3083-3072 / 99113-4221",
    localizacao: "PE",
  },
  {
    empresa: "MARIA ABADIA BORGES DE SOUZA",
    nome: "Abadia",
    email: "acmrep@outlook.com",
    telefone: "(62) 3259-1249 / 98531-1000",
    localizacao: "GO",
  },
  {
    empresa: "PARTNER ASSESSORIA E REPRESENTAÇÃO LTDA",
    nome: "Fernanda / Cassio",
    email: "partner@partnermg.com.br",
    telefone: "(31) 3088-6540 / (31) 99615-9315 / (31) 98448-5724",
    localizacao: "MG",
  },
  {
    empresa: "RIVALDO BEZERRA PRIMO",
    nome: "Rivaldo",
    email: "rivaldo2930@gmail.com",
    telefone: "(61) 98408-7736",
    localizacao: "DF",
  },
  {
    empresa: "VIGOKAR COMERCIO E REPRESENTÇÕES LTDA",
    nome: "Wilson",
    email: "vigokar@terra.com.br",
    telefone: "(47) 99991-8280",
    localizacao: "SC",
  },
  {
    empresa: "Comercio e Representações Faros LTDA",
    nome: "Luiz",
    email: "faroscom@uol.com.br",
    telefone: "(48) 3266-6079 / (48) 99983-6012",
    localizacao: "EXPORTAÇÃO",
  },
];
