import { useState } from 'react'
import MobileHeader from '../../MobileHeader'
import MobileFooter from '../../MobileFooter'
import { useTranslation } from 'react-i18next'
import SettingsIcon from '@mui/icons-material/Settings'
import ExtensionIcon from '@mui/icons-material/Extension'
import LanguageIcon from '@mui/icons-material/Language'
import CompanyBg from '../../../images/background.png'
import './company.css'

export default function Company() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [showSideBar, setShowSideBar] = useState(false)
  const [lingua, setLingua] = useState('portugues')

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="bg-company">
        <span>{t('empresa')}</span>
        <span style={{ fontSize: '53px', marginTop: '-20px' }}>-</span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 30,
        }}
      >
        <div
          style={{
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <SettingsIcon sx={{ height: '55px', width: '55px' }} />
          <span style={{ fontSize: 30, fontWeight: 700 }}>+ 60</span>
          <span style={{ fontSize: 18 }}>{t('LINHASdePRODUTO')}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <ExtensionIcon sx={{ height: '55px', width: '55px' }} />
          <span style={{ fontSize: 30, fontWeight: 700 }}>+ 2500</span>
          <span style={{ fontSize: 18 }}>{t('PRODUTOS')}</span>
        </div>
        <div
          style={{
            marginBottom: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 12,
          }}
        >
          <LanguageIcon sx={{ height: '55px', width: '55px' }} />
          <span style={{ fontSize: 30, fontWeight: 700 }}>+ 10</span>
          <span style={{ fontSize: 18 }}>{t('PAISES')}</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={CompanyBg} alt="Imagem da Marvini" width={272} height={183} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        <span
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: 'rgba(51, 51, 51, 0.5)',
            maxWidth: '289px',
            textAlign: 'center',
          }}
        >
          {t('textoSobreEmpresa')}
        </span>
      </div>
      <MobileFooter t={t} />
    </div>
  )
}
