import { useState, useEffect } from 'react'
import MobileHeader from '../../MobileHeader'
import MobileFooter from '../../MobileFooter'
import icone_superentrega from '../../../images/icone_superentrega.png'
import madeInBrasil from '../../../images/madeInBrasil.png'
import icone_assistenciatecnica from '../../../images/icone_assistenciatecnica.png'
import qualidadeDiamante from '../../../images/qualidadeDiamante.png'
import icone_garantia from '../../../images/icone_garantia.png'
import icone_certificado from '../../../images/icone_certificado.png'
import api from '../../../services/api'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import MobileCarrouselComponent from '../../MobileCarrouselComponent'
import WhatsAppWidget from 'react-whatsapp-widget'
import './home.css'

export default function HomePage() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const [showSideBar, setShowSideBar] = useState(false)
  const [lista, setLista] = useState([])
  const [lingua, setLingua] = useState('portugues')
  const [completeProducts, setCompleteProducts] = useState([])
  const [exclusiveProducts, setExclusiveProducts] = useState([])
  const [completeDiamondProds, setCompleteDiamondsProds] = useState([])

  useEffect(() => {
    const btnIniciarConversa = document.querySelector('._2W95M')

    btnIniciarConversa.id = 'btn-iniciar-conversa'

    const botaoConversa = document.getElementById('btn-iniciar-conversa')

    botaoConversa?.addEventListener('click', function (e) {
      e.stopPropagation()
      e.preventDefault()

      window.open(
        `https://api.whatsapp.com/send/?phone=5511940768783&text=${t(
          'whatstapp-apiMessage'
        )}&app_absent=0`
      )
    })
  }, [])

  useEffect(() => {
    showImages()
  }, [lingua])

  useEffect(() => {
    showCompleteLineProducts()
    showExclusiveProducts()
    showDiamondQuality()
  }, [])

  const showImages = async () => {
    try {
      const response = await api.get(`imagens/${lingua}`)
      setLista(response?.data?.imagens)
    } catch (e) {
      console.log(e)
    }
  }
  const showCompleteLineProducts = async () => {
    try {
      const response = await api.get('produtos/carrossel')
      setCompleteProducts(response?.data?.produtos)
    } catch (e) {
      console.log(e)
    }
  }
  const showExclusiveProducts = async () => {
    try {
      const response = await api.get('produtos/carrossel2')
      setExclusiveProducts(response?.data?.produtos)
    } catch (error) {
      console.log(error)
    }
  }
  const showDiamondQuality = async () => {
    const response = await api.get('produtos/carrossel3')
    setCompleteDiamondsProds(response?.data?.produtos)
  }

  return (
    <div className="container-mobile">
      <MobileHeader
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        lingua={lingua}
        setLingua={setLingua}
        i18n={i18n}
        t={t}
      />
      <div className="swiper-bg">
        <Swiper
          spaceBetween={10}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          navigation={true}
          style={{
            width: '100%',
            height: '150px',
            zIndex: 10,
          }}
        >
          {lista?.map((item, index) => {
            return (
              <SwiperSlide>
                <img
                  key={index}
                  style={{
                    backgroundSize: '100% 100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',
                    width: '100%',
                    backgroundImage: `url(https://api.marvini.com.br/${item?.caminho})`,
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <div className="quality-banners">
        <div className="banner-mobile" style={{ marginLeft: '17px' }}>
          <img
            src={icone_superentrega}
            style={{
              paddingLeft: '10px',
              minWidth: '90px',
              maxWidth: '90px',
              maxHeight: '46px',
              minHeight: '46px',
            }}
          />
          <span className="banner-title">{t('EntregaRapida')}</span>
          <span className="banner-text">{t('EntregaRapidaDescript')}</span>
        </div>
        <div className="banner-mobile">
          <img src={madeInBrasil} />
          <span className="banner-title">{t('PagamentoSeguro')}</span>
          <span className="banner-text">{t('PagamentoSeguroDescript')}</span>
        </div>
        <div className="banner-mobile">
          <img
            src={icone_assistenciatecnica}
            style={{
              minWidth: '46px',
              maxWidth: '46px',
              maxHeight: '46px',
              minHeight: '46px',
            }}
          />
          <span className="banner-title">{t('MelhorQualidade')}</span>
          <span className="banner-text">{t('MelhorQualidadeDescript')}</span>
        </div>
        <div className="banner-mobile">
          <img
            src={qualidadeDiamante}
            style={{
              minWidth: '60px',
              maxWidth: '60px',
              minHeight: '60px',
              maxHeight: '60px',
            }}
          />
          <span className="banner-title">{t('RetornoGarantido')}</span>
          <span className="banner-text">{t('RetornoGarantidoDescript')}</span>
        </div>
        <div className="banner-mobile">
          <img
            src={icone_garantia}
            style={{ minWidth: '70px', maxWidth: '70px' }}
          />
          <span className="banner-title">{t('RetornoGarantido2')}</span>
          <span className="banner-text">{t('RetornoGarantidoDescript2')}</span>
        </div>
        <div className="banner-mobile" style={{ marginRight: '34px' }}>
          <img
            src={icone_certificado}
            style={{
              minWidth: '50px',
              maxWidth: '50px',
              maxHeight: '50px',
              minHeight: '50px',
            }}
          />
          <span className="banner-title">{t('RetornoGarantido3')}</span>
          <span className="banner-text">{t('RetornoGarantidoDescript3')}</span>
        </div>
      </div>
      <div>
        <div className="carrossel-container">
          <div className="div-title-carroussel">
            <div className="side-span" />
            <span>{t('Destaques')}</span>
            <div className="side-span" />
          </div>
          <MobileCarrouselComponent data={completeProducts} />
        </div>
        <div className="carrossel-container-prod-exclusive">
          <div className="div-title-carroussel">
            <div className="side-span" />
            <span style={{ maxWidth: '145px' }}>{t('top10')}</span>
            <div className="side-span" />
          </div>
          <MobileCarrouselComponent data={exclusiveProducts} />
        </div>
      </div>
      <div className="div-saiba-mais">
        <span>{t('saibaMais')}</span>
      </div>
      <div>
        <div className="carrossel-container-prod-exclusive">
          <div className="div-title-carroussel">
            <div className="side-span" />
            <span style={{ maxWidth: '210px' }}>{t('nossosProdutos')}</span>
            <div className="side-span" />
          </div>
          <MobileCarrouselComponent data={completeDiamondProds} />
        </div>
      </div>
      <div style={{ position: 'fixed', bottom: 30, right: 0 }}>
        <WhatsAppWidget
          textReplyTime={t('whatsapp-messageTextReplyTime')}
          companyName="Marvini"
          message={t('whatsapp-message')}
          sendButton={t('whatsapp-sendButton')}
        />
      </div>
      <MobileFooter t={t} />
    </div>
  )
}
