import Slider from 'react-slick'
import { Box, Card, CardContent } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'

const CarrouselComponent = ({ array }) => {
  const history = useHistory()

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: array?.length < 5 ? array?.length : 5,
    slidesToScroll: array?.length < 5 ? array?.length : 5,
    autoplay: true,
    prevArrow: (
      <ChevronLeft
        sx={{
          color: '#999',
          width: 30,
          height: 30,
          marginLeft: -1,
          '&:hover': {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: '50%',
            width: 30,
            height: 30,
            marginLeft: -1,
          },
        }}
      />
    ),
    nextArrow: (
      <ChevronRight
        sx={{
          color: '#999',
          width: 30,
          height: 30,
          marginRight: -3,
          '&:hover': {
            color: '#fff',
            backgroundColor: '#999',
            borderRadius: '50%',
            width: 30,
            height: 30,
            marginRight: -3,
          },
        }}
      />
    ),
  }

  return (
    <Box
      className="Banner"
      sx={{
        maxWidth:
          array?.length >= 5
            ? 1170
            : array?.length === 4
            ? 940
            : array?.length === 3
            ? 710
            : array?.length === 2
            ? 470
            : false,
        marginLeft: 16,
      }}
    >
      <Slider {...settings}>
        {array?.map((item) => {
          return (
            <div key={item?.id_produto}>
              <Card
                onClick={() => history.push(`/produto/${item?.id_produto}`)}
                className="card"
                sx={{
                  padding: '1rem 0',
                  wordBreak: 'break-all',
                  textDecoration: 'none',
                  color: '#000',
                  cursor: 'pointer',
                  width: 220,
                  boxShadow: 'none',
                }}
              >
                <CardContent>
                  <h3 title={item?.nome} className="title">
                    {item?.nome}
                  </h3>
                </CardContent>
                <div
                  style={{
                    minHeight: '150px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      maxWidth: '90%',
                      maxHeight: '90%',
                      margin: '0 auto',
                    }}
                    alt="produto"
                    src={`https://api.marvini.com.br/${item?.image1}`}
                  />
                </div>
                <h3
                  title={item?.subtitulo}
                  className="title2"
                  style={{
                    margin: '0px 12px',
                    wordBreak: 'break-word',
                  }}
                >
                  {item?.subtitulo}
                </h3>
              </Card>
            </div>
          )
        })}
      </Slider>
    </Box>
  )
}

export default CarrouselComponent
