/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import logoMarvini from '../../images/logoMarvini.png'
import FlagsButton from '../../components/FlagsButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import './produtoEsp.css'
import { useParams } from 'react-router'
import certificadoIso from '../../images/certificadoIso.png'
import garantia from '../../images/garantia.png'
import {
  App,
  Container,
  Container2,
  Header,
  TitleHome,
  Container5,
  Container5Text,
  Container5Text2,
  Container5Text3,
  Background2,
  ButtonFacebook,
  ButtonYoutube,
  ButtonLinkedin,
  ButtonInstagram,
  ButtonContato,
  ButtonEmpresa,
  ButtonInicio,
  ButtonProdutos,
  ButtonVideos,
  Background3,
  Background4,
  FraseTitle,
  ContainerFinalpage,
  Container5Buttons,
  ContainerAcesso,
  ProdutoCategoria,
  ProdutoReferencia,
  ProdutoTitulo,
  Container3Pontos,
  Container3Pontos2,
  TitleBanner,
} from './styles'
import api from '../../services/api'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import ImageGallery from 'react-image-gallery'
import { Button, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Link } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ProductShow from '../../components/MobileMarvini/ProductShow/productShow'

interface teste2 {
  caminho: any
  nome: any
  data: { caminho: string }
}

interface id_produto {
  id_produto: any
}

const produtoEsp: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id_produto } = useParams<id_produto>()
  const [nome, setNome] = useState('')
  const [codigo, setCodigo] = useState('')
  const [codigoReferencia, setCodigoReferencia] = useState('')
  const [numero, setNumero] = useState('')
  const [subtitulo, setSubtitulo] = useState('')
  const [sensor, setSensor] = useState('')
  const [bico, setBico] = useState('')
  const [volumeMax, setVolumeMax] = useState('')
  const [volumeMin, setVolumeMin] = useState('')
  const [descricao, setDescricao] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [lista, setlista] = useState(Array())
  const { width } = useWindowDimensions()

  useEffect(() => {
    async function exibirProduto() {
      console.log(id_produto)
      const teste3 = await api.get<teste2[], any>(`/produtos/${id_produto}`)

      setNome(teste3.data.produto.nome)
      setCodigo(teste3.data.produto.codigo)
      setCodigoReferencia(teste3.data.produto.codigoReferencia)
      setNumero(teste3.data.produto.numero)
      setSubtitulo(teste3.data.produto.subtitulo)
      setSensor(teste3.data.produto.sensor)
      setBico(teste3.data.produto.bico)
      setVolumeMax(teste3.data.produto.volumeMax)
      setVolumeMin(teste3.data.produto.volumeMin)
      setDescricao(teste3.data.produto.descricao)
      setDataCriacao(teste3.data.produto.criacao)
      console.log(teste3.data.produto)
      let array_images = []
      if (teste3.data.produto.image1)
        array_images.push({
          original: `https://api.marvini.com.br/${teste3.data.produto.image1}`,
          thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image1}`,
        })
      if (teste3.data.produto.image2)
        array_images.push({
          original: `https://api.marvini.com.br/${teste3.data.produto.image2}`,
          thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image2}`,
        })
      if (teste3.data.produto.image3)
        array_images.push({
          original: `https://api.marvini.com.br/${teste3.data.produto.image3}`,
          thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image3}`,
        })
      if (teste3.data.produto.image4)
        array_images.push({
          original: `https://api.marvini.com.br/${teste3.data.produto.image4}`,
          thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image4}`,
        })
      if (teste3.data.produto.image5)
        array_images.push({
          original: `https://api.marvini.com.br/${teste3.data.produto.image5}`,
          thumbnail: `https://api.marvini.com.br/${teste3.data.produto.image5}`,
        })
      setlista(array_images)

      // console.log(lista);
    }
    if (id_produto) {
      exibirProduto()
    }
  }, [])

  useEffect(() => {
    // console.log(lista);
  }, [lista])

  // console.log(lista);

  return width < 600 ? (
    <ProductShow />
  ) : (
    <App>
      <Header>
        <Container>
          <div className="botoesInicio">
            <img
              className="btn"
              onClick={() => history.push('/')}
              style={{
                width: '180px',
                height: 'auto',
                position: 'absolute',
                left: '70px',
                top: '-30%',
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
              src="/LogoMarvini.png"
            ></img>
            <button className="btn" onClick={() => history.push('/empresa')}>
              {t('btnEmpresa')}
            </button>
            <button className="btn" onClick={() => history.push('/produtos')}>
              {t('btnProduto')}
            </button>
            <button className="btn" onClick={() => history.push('/contato')}>
              {t('btnContato')}
            </button>
            <button className="btn" onClick={() => history.push('/videos')}>
              {t('btnVideos')}
            </button>
          </div>

          <div
            style={{
              fontSize: '20px',
              position: 'absolute',
              right: '30px',
              top: '110px',
            }}
          >
            {t('marvini')} <b>{t('valoriza')}</b> {t('seuCarro')}
          </div>
          <TitleHome>
            {t('produtosTitulo')}
            <br />―
          </TitleHome>
          <FlagsButton setLingua={() => {}} />
        </Container>
      </Header>
      <div className="imaTitulo">
        <div className="prodFundo">
          <div
            style={{
              padding: '1.2rem',
              minWidth: '50%',
              border: '2px solid #999',
            }}
          >
            <ImageGallery
              showFullscreenButton={false}
              useBrowserFullscreen={false}
              showPlayButton={false}
              showNav={true}
              slideOnThumbnailOver
              items={lista}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1rem',
              marginLeft: '1rem',
              alignItems: 'start',
            }}
          >
            <ProdutoCategoria style={{ marginBottom: '16px' }}>
              {nome}
            </ProdutoCategoria>
            <ProdutoReferencia style={{ marginBottom: '16px' }}>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('CÓDIGO')}:
              </span>{' '}
              {codigo}
            </ProdutoReferencia>
            {/* <ProdutoTitulo style={{ marginBottom: "16px" }}>
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                {t("NÚMERO")}:
              </span>{" "}
              {numero}
            </ProdutoTitulo> */}
            <ProdutoTitulo style={{ marginBottom: '16px' }}>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('códigoDeReferência')}:
              </span>{' '}
              {codigoReferencia
                ? codigoReferencia
                : t('codigoRefNaoEspecificado')}
            </ProdutoTitulo>
          </div>
          <div style={{ margin: '1rem 1rem 0 0' }}>
            <IconButton
              sx={{
                display: 'flex',
                flexDirection: 'column',
                color: 'black',
                padding: '1rem',
              }}
              component={Link}
              to="/produtos"
            >
              <Typography>Voltar</Typography>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="caixaDescricao">
        <div className="descricao">
          <text className="descricaoTxt">{t('Descrição')}</text>
        </div>
        <div className="descricaoText">
          <text className="titulos">{descricao}</text>
          <text className="titulos">
            <b>{t('Subtítulo')}:</b> {subtitulo}
          </text>
          {sensor === 'Não possui' ? (
            false
          ) : (
            <text className="titulos">
              <b>{t('Sensor')}:</b> {sensor}
            </text>
          )}
          {bico === 'Não possui' ? (
            false
          ) : (
            <text className="titulos">
              <b>{t('Bico')}:</b> {bico}
            </text>
          )}
          <text className="titulos">
            <b>{t('VolumeMáximoDeÁgua')}:</b> {volumeMax} ml
          </text>
          <text className="titulos">
            <b>{t('VolumeMínimoDeÁgua')}:</b> {volumeMin} ml
          </text>
        </div>
      </div>
      <Container2
        style={{
          padding: '0 15% 0 10%',
          overflow: 'hidden',
          position: 'relative',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Container3Pontos
          style={{
            marginTop: '-3%',
            userSelect: 'none',
            color: 'rgba(211,211,211, .5)',
            marginLeft: '1rem',
          }}
        >
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
        </Container3Pontos>
        <TitleBanner>{t('QuerSaberMaisSobreAMarvini')}?</TitleBanner>
        <FraseTitle style={{ margin: '0', maxWidth: '17ch' }}>
          {t('EntreEmContatoConosco')}!
        </FraseTitle>
        <button
          className="contato"
          style={{
            margin: '0',
            width: 'max-content',
            height: 'max-content',
            padding: '1rem',
          }}
          onClick={() => history.push('/contato')}
        >
          {t('CliqueAqui')}
        </button>
        <Container3Pontos2
          style={{ marginLeft: '80%', marginTop: '12%', userSelect: 'none' }}
        >
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
          ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp; ●&emsp; &emsp;●
          <br />
          <br />
        </Container3Pontos2>
      </Container2>
      <Container5>
        <ContainerFinalpage>
          <Background2 src={logoMarvini}></Background2>
          {/* <Container5Text>{t("descricaoFim")}</Container5Text> */}
          <Container5Text2>{t('nosSiga')}</Container5Text2>
          <Container5Buttons>
            <a href="https://www.facebook.com/marvini.automotivo">
              <ButtonFacebook />
            </a>
            <a href="https://www.youtube.com/channel/UCPt4aosoTeGvsS1xlgOPq4Q">
              <ButtonYoutube />
            </a>
            <a href="http://www.linkedin.com/in/marvini-autopeças-3b2b42235">
              <ButtonLinkedin />
            </a>
            <a href="https://www.instagram.com/marvini.automotivo/">
              <ButtonInstagram />
            </a>
          </Container5Buttons>
        </ContainerFinalpage>
        <ContainerAcesso>
          <Container5Text3>{t('acessoRapido')}</Container5Text3>
          <ButtonInicio onClick={() => history.push('/')}>
            {t('inicio')}
          </ButtonInicio>
          <ButtonEmpresa onClick={() => history.push('/empresa')}>
            {t('empresa')}
          </ButtonEmpresa>
          <ButtonProdutos onClick={() => history.push('/produtos')}>
            {t('produtos')}
          </ButtonProdutos>
          <ButtonContato onClick={() => history.push('/contato')}>
            {t('contato')}
          </ButtonContato>
          <ButtonVideos onClick={() => history.push('/videos')}>
            {t('videos')}
          </ButtonVideos>
        </ContainerAcesso>
        <Background3 src={garantia}></Background3>
        <Background4 src={certificadoIso}></Background4>
      </Container5>
    </App>
  )
}

export default produtoEsp
