import styled from "styled-components";
import media from "styled-media-query";
import facebook from "../../images/facebook.png";
import youtube from "../../images/youtube.png";
import linkedin from "../../images/linkedin.png";
import instagram from "../../images/instagram.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoClock = styled.img`
  /* margin-top: 9%;
  margin-bottom: 0%;
  margin-left: 20%; */
`;

export const Part = styled.div`
  margin-left: 15%;
  margin-right: 5%;
  font-size: 15px;

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    font-size: 12px;
  }

  span {
    font-weight: bold;
  }
`;

export const LogoPagamento = styled.img`
  /* margin-top: 0%;
  margin-bottom: 2%;
  margin-right: 0%; */
`;

export const Part2 = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  font-size: 15px;
  span {
    font-weight: bold;
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    margin-top: -10px;
    font-size: 12px;
  }
`;

export const LogoQualidade = styled.img`
  /* margin-top: 0%;
  margin-bottom: 2%;
  margin-left: 0%;
  margin-right: 0%; */
`;

export const Part3 = styled.div`
  top: 50%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 15px;
  span {
    font-weight: bold;
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    font-size: 12px;
  }
`;

export const LogoSeguranca = styled.img`
  /* margin-top: 0%;
  margin-bottom: 1%;
  margin-left: 0%;
  margin-right: 0%; */
`;

export const LogoGarantia = styled.img`
  /* margin-top: 0%;
  margin-bottom: 2%;
  margin-left: 0%;
  margin-right: 0%; */
`;

export const LogoIso = styled.img`
  /* margin-top: 0%;
  margin-bottom: 1%;
  margin-left: 0%;
  margin-right: 0%; */
`;

export const Part4 = styled.div`
  top: 50%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 15px;
  span {
    font-weight: bold;
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    font-size: 12px;
  }
`;

export const Part7 = styled.div`
  top: 50%;
  margin-left: 2%;
  margin-right: 2%;
  font-size: 15px;
  span {
    font-weight: bold;
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    font-size: 12px;
  }
`;

export const LogoMarvini = styled.img`
  width: 30%;
  margin-top: 100%;
  opacity: 0.2;
  bottom: 0%;
`;

export const Part5 = styled.div`
  margin-top: 2%;
  text-align: center;
  margin-bottom: 2%;
  span {
    font-size: 30px;
    font-weight: bold;
  }
`;

export const Part6 = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  font-size: 15px;
  span {
    font-weight: bold;
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    font-size: 12px;
  }
`;

export const Background = styled.img`
  position: relative;
  display: flex;
  width: min(50%, 500px);
  height: min(50%, 500px);
  border-radius: 10px;
  margin-top: 0%;
  right: 0%;
`;

export const BackgroundCards = styled.img`
  display: flex;
  width: 170px;
  height: 150px;
  margin-top: 10%;
  /* margin-left: 25%; */
  margin-bottom: 0%;
`;

export const Anchor = styled.a`
  background-color: #e5e5e5;
  min-height: 26vh;
  min-width: 100%;
  text-align: center;
  display: flex;
  margin-top: 0.3%;
  @media (min-width: 768px) and (max-width: 1366px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    background-color: #e5e5e5;
    min-height: 25vh;
    min-width: 100%;
    text-align: center;
    display: flex;
    margin-top: 0.3%;
  }
`;

export const Container2 = styled.div`
  min-height: 50vh;
  min-width: 50%;
  display: flex;
  flex-direction: column;
`;

export const Container2Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-left: 5%;
  margin-top: 3%;
  color: #1b2957;
`;

export const Container4Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-top: 3%;
  color: #1b2957;
`;

export const Container3 = styled.div`
  background-color: #2d32a8;
  height: 100%;
  display: flex;
  margin-top: 3%;
  align-items: center;
`;

export const Container3Title = styled.div`
  margin-left: 26%;
  margin-top: 5%;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: white;
`;

export const Container3Text = styled.div`
  margin-left: 40%;
  text-align: center;
  font-size: 20px;
  color: white;
  margin-right: 15%;
  text-align: justify;
  margin-top: 2%;
`;

export const CustomButton = styled.button`
  display: flex;
  margin-top: 4%;
  margin-left: 50%;
  text-align: left;
  background-color: #2d32a8;
  background-repeat: no-repeat;
  background-size: 100px 100px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 40px;
  border-color: white;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5%;
  &:hover {
    background: rgb(0, 13, 133);
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Container3Pontos = styled.div`
  position: relative;
  display: flex;
  font-size: 25px;
  color: white;
  font-weight: bold;
  margin-left: 50%;
`;

export const NomeProduto = styled.div`
  position: absolute;
  top: 320px;
  display: no-repeat;
  font-size: 25px;
  color: black;
  font-weight: bold;
  left: 60px;
  right: 50px;
`;

export const Container4 = styled.div`
  min-height: 30vh;
  min-width: 50%;
`;

export const Container5Buttons = styled.div`
  display: flex;
  margin-left: 20%;
  margin-top: 2%;
  height: 100%;
  width: 80%;
`;

export const Button2 = styled.button`
  color: #2d32a8;
  background-color: white;
  margin-left: 20px;
  font-size: 15px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const Container4e5 = styled.div`
  min-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(../../images/background.png);
  background-size: cover;
  ${media.between("medium", "large")`
    /* screen width is between 768px (medium) and 1170px (large) */
    background-size: cover;
  `}
`;

export const Container4e5Title = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 40%;
  margin-top: 0%;
  font-size: 30px;
  color: white;
  padding-top: 2%;
  text-shadow: 0px 0px 5px black;
  font-weight: bold;
  @media (min-width: 768px) and (max-width: 1440px) {
    /* screen width is between 768px (medium) and 1370px (large) */
    margin-left: 35%;
  }
`;

export const Background4e5 = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const ContainerFinalpage = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
`;

export const Container5 = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
`;

export const ContainerAcesso = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  margin-left: 20%;
  flex-direction: column;
  align-items: center;
  margin-top: 6%;
`;

export const Container5e2 = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
`;

export const Background2 = styled.img`
  width: min(100%, 150px);
  border-radius: 10px;
  margin-left: 30%;
  margin-top: 15%;
`;

export const Container5Text = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  text-align: justify;
`;

export const Container5Text2 = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  font-weight: bold;
  right: 70%;
  text-align: justify;
`;

export const ButtonFacebook = styled.button`
  margin-top: 5%;
  margin-left: 7%;
  color: #1976d2;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${facebook});
  background-size: 75% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonYoutube = styled.button`
  margin-top: 5%;
  margin-left: 10%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${youtube});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonLinkedin = styled.button`
  margin-top: 1%;
  margin-left: 14%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${linkedin});
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonInstagram = styled.button`
  width: 2%;
  margin-top: 5%;
  margin-left: 16%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${instagram});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Container5Text3 = styled.div`
  display: flex;
  text-align: start;
  margin-top: 0%;
  font-size: 20px;
  color: black;
  margin-left: 2%;
  font-weight: bold;
  margin-bottom: 5%;
`;

export const ButtonInicio = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  background-color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonEmpresa = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  font-weight: bold;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonProdutos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonContato = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonVideos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const Background3 = styled.img`
  width: min(100%, 200px);
  height: min(100%, 200px);
  margin-top: 9%;
  margin-left: 15%;
`;

export const Background4 = styled.img`
  width: min(100%, 150px);
  height: min(100%, 150px);
  margin-top: 8.7%;
  margin-left: 3%;
  margin-right: 5%;
`;

export const Carrossel = styled.img`
  position: absolute;
  width: 50px;
  background-color: blue;
`;
