import styled from "styled-components";

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
`;

export const Container = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  @media screen and (max-width: 768px) {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    right: 5px;
  }
  @media screen and (max-width: 400px) {

  }
  z-index: 1;
`;
