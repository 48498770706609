import styled from "styled-components";
import facebook from "../../images/facebook.png";
import youtube from "../../images/youtube.png";
import linkedin from "../../images/linkedin.png";
import instagram from "../../images/instagram.png";

export const App = styled.div`
  text-align: center;
  width: 100vw;
`;

export const Header = styled.div`
  background-color: #3a3736;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url(../../images/videos.png);
  background-size: cover;
  background-position: center;
`;

export const Container = styled.div`
  position: relative;
  height: 100vmin;
  width: 100%;
  display: inline-block;
  // background-color: rgba(0, 0, 0, 0.3);
  text-align: left;
`;

export const ContainerFinalpage = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
`;

export const Container5Buttons = styled.div`
  display: flex;
  margin-left: 20%;
  margin-top: 10%;
  height: 100%;
  width: 100%;
`;

export const ContainerAcesso = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
  margin-left: 20%;
  flex-direction: column;
  align-items: center;
  margin-top: 6%;
`;

export const TitleHome = styled.div`
  display: flex;
  margin-top: 10%;
  margin-left: 43%;
  font-size: 60px;
  color: white;
  text-shadow: 0px 0px 5px black;
  font-weight: bold;
  text-align: center;
`;

export const FraseTitle = styled.div`
  margin-top: 1%;
  margin-bottom: 3%;
  font-size: 20px;
  text-align: center;
  margin-left: 0%;
  color: black;
`;

export const TitleVideo = styled.div`
  display: flex;
  margin-top: 3%;
  margin-left: 40%;
  font-size: 30px;
  color: black;
  font-weight: bold;
`;

export const FraseVideo = styled.div`
  margin-top: 1%;
  font-size: 17px;
  margin-bottom: 10%;
  text-align: center;
  margin-left: 3%;
  margin-right: 3%;
  color: black;
`;

export const Container2 = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  background-color: #ececec;
  text-align: left;
`;

export const Video1 = styled.div`
  display: inline-block;
  margin-left: 6%;
  margin-top: 3%;
  background-color: white;
  width: 26.1%;
  height: 50%;
  text-align: center;
`;

export const Video2 = styled.div`
  display: inline-block;
  margin-left: 6%;
  margin-top: 3%;
  background-color: white;
  width: 26.1%;
  height: 80%;
  text-align: center;
  margin-bottom: 3%;
`;

export const Container5 = styled.div`
  background-color: white;
  height: 100%;
  display: flex;
`;

export const Background2 = styled.img`
  width: min(100%, 150px);
  border-radius: 10px;
  margin-left: 30%;
  margin-top: 15%;
`;

export const Container5Text = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  text-align: justify;
`;

export const Container5Text2 = styled.div`
  margin-top: 10%;
  margin-left: 30%;
  font-size: 20px;
  color: black;
  font-weight: bold;
  right: 70%;
  text-align: justify;
`;

export const ButtonFacebook = styled.button`
  margin-top: 5%;
  margin-left: 7%;
  color: #1976d2;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${facebook});
  background-size: 75% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonYoutube = styled.button`
  margin-top: 5%;
  margin-left: 10%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  background-image: url(${youtube});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  cursor: pointer;
  border-color: #1976d2;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonLinkedin = styled.button`
  margin-top: 1%;
  margin-left: 14%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${linkedin});
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const ButtonInstagram = styled.button`
  width: 2%;
  margin-top: 5%;
  margin-left: 16%;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 30px 30px;
  border-radius: 100px;
  cursor: pointer;
  border-color: #1976d2;
  background-image: url(${instagram});
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    -webkit-box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.2);
  }
`;

export const Container5Text3 = styled.div`
  display: flex;
  text-align: start;
  margin-top: 0%;
  font-size: 20px;
  color: black;
  margin-left: 2%;
  font-weight: bold;
  margin-bottom: 5%;
`;

export const ButtonInicio = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  background-color: white;
  font-weight: bold;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonEmpresa = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  color: #1976d2;
  font-weight: bold;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonProdutos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonContato = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonVideos = styled.button`
  margin-top: 5%;
  margin-left: 0%;
  font-weight: bold;
  color: #1976d2;
  background-color: white;
  font-size: 15px;
  padding: 10px 40px;
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const Background3 = styled.img`
  width: min(100%, 200px);
  height: min(100%, 200px);
  margin-top: 9%;
  margin-left: 15%;
`;

export const Background4 = styled.img`
  width: min(100%, 150px);
  height: min(100%, 150px);
  margin-top: 8.7%;
  margin-left: 3%;
  margin-right: 5%;
`;

export const Carrossel = styled.img`
  position: absolute;
  width: 50px;
  background-color: blue;
`;
